export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Any: any;
  Date: any;
  DateTime: any;
  Decimal: any;
  Long: any;
  UUID: any;
};

export type ActionButtonComponent = {
  __typename?: 'ActionButtonComponent';
  actionButtonStyle?: Maybe<Scalars['String']>;
  attributeForSource?: Maybe<Scalars['String']>;
  attributeForUpdate?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  componentType: Scalars['String'];
  displayOnlyWhenSelected: Scalars['Boolean'];
  entity?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isDrawableOnFinish: Scalars['Boolean'];
  metComponentId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  providerAuthorizationRole?: Maybe<Scalars['String']>;
  required: Scalars['Boolean'];
  style?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  uniqueID: Scalars['String'];
  userDefinedId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  width: Scalars['Int'];
};

export type ActionEpisodeResult = {
  __typename?: 'ActionEpisodeResult';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type AddQuickNoteDtoInput = {
  actions: Array<InputMaybe<ChecklistActionDataInput>>;
  baseQuickNoteId?: InputMaybe<Scalars['Int']>;
  episodeId?: InputMaybe<Scalars['Int']>;
  patientId: Scalars['Int'];
  quickNoteType: QuickNoteType;
  reasonForNote: Scalars['Int'];
};

export type AddressDto = {
  __typename?: 'AddressDto';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  isPrimary: Scalars['Boolean'];
  state?: Maybe<Scalars['String']>;
  type: AddressType;
  zip?: Maybe<Scalars['String']>;
};

export type AddressDtoInput = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  isPrimary: Scalars['Boolean'];
  state?: InputMaybe<Scalars['String']>;
  type: AddressType;
  zip?: InputMaybe<Scalars['String']>;
};

export enum AddressType {
  Alternate = 'ALTERNATE',
  Main = 'MAIN',
  Third = 'THIRD',
}

export type AllowedFileTypesAndSizeModel = {
  __typename?: 'AllowedFileTypesAndSizeModel';
  fileSizeLimitInBytes: Scalars['Int'];
  fileTypes: Scalars['String'];
};

export type AppealGrievViewModel = {
  __typename?: 'AppealGrievViewModel';
  anyProgress?: Maybe<Scalars['Boolean']>;
  authStatus?: Maybe<LookupValue>;
  counter?: Maybe<CounterClockColumnViewModel>;
  createdBy?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['Date']>;
  episodeNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  receiptDate?: Maybe<Scalars['DateTime']>;
  state?: Maybe<EpisodeState>;
  tat?: Maybe<TatClockColumnViewModel>;
  timeZone?: Maybe<Scalars['String']>;
  topReminder?: Maybe<BaseReminder>;
  type?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
}

export type AssociatedClaimActionValue = {
  __typename?: 'AssociatedClaimActionValue';
  billingProviderName?: Maybe<Scalars['String']>;
  billingProviderTitle?: Maybe<Scalars['String']>;
  checkDate?: Maybe<Scalars['Date']>;
  claimDate?: Maybe<Scalars['Date']>;
  claimId?: Maybe<Scalars['String']>;
  claimReceivedDate?: Maybe<Scalars['Date']>;
  diagnosisCodes?: Maybe<Array<Maybe<MedicationCode>>>;
  id: Scalars['Int'];
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  memberBenefitNetwork?: Maybe<Scalars['String']>;
  memberResponsibility?: Maybe<Scalars['Decimal']>;
  paidAmount?: Maybe<Scalars['Decimal']>;
  procedureCodes?: Maybe<Array<Maybe<MedicationCode>>>;
  provider?: Maybe<Provider>;
  providerId?: Maybe<Scalars['Int']>;
  serviceFrom?: Maybe<Scalars['Date']>;
  serviceLocationName?: Maybe<Scalars['String']>;
  serviceTo?: Maybe<Scalars['Date']>;
};

export type AssociatedClaimAttributes = {
  __typename?: 'AssociatedClaimAttributes';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type AssociatedClaimOrderableOptions = {
  __typename?: 'AssociatedClaimOrderableOptions';
  associatedClaimAttributes: Array<AssociatedClaimAttributes>;
  maxAllowed: Scalars['Int'];
  minRequired: Scalars['Int'];
};

export type AssociatedEpisodeActionValue = {
  __typename?: 'AssociatedEpisodeActionValue';
  authorizationStatus?: Maybe<Scalars['Int']>;
  decisionById?: Maybe<Scalars['Int']>;
  decisionDateTime?: Maybe<Scalars['DateTime']>;
  determinationDateTime?: Maybe<Scalars['DateTime']>;
  diagnosisCode?: Maybe<Scalars['String']>;
  episodeDateTime?: Maybe<Scalars['DateTime']>;
  episodeId?: Maybe<Scalars['String']>;
  episodePathway?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  mRN?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
  procedureCode?: Maybe<Scalars['String']>;
  provider?: Maybe<Provider>;
  receivedDateTime?: Maybe<Scalars['DateTime']>;
  requestingClinicianPar?: Maybe<Scalars['Boolean']>;
  requestingProviderId?: Maybe<Scalars['Int']>;
};

export type AssociatedEpisodeOrderableOptions = {
  __typename?: 'AssociatedEpisodeOrderableOptions';
  maxAllowed: Scalars['Int'];
  minRequired: Scalars['Int'];
};

export type Attachment = IAuditable & {
  __typename?: 'Attachment';
  attachmentCategory?: Maybe<LookupValue>;
  attachmentType?: Maybe<LookupValue>;
  createdBy: Scalars['Int'];
  createdByUser?: Maybe<User>;
  createdOn?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  fileExtension: Scalars['String'];
  id: Scalars['Int'];
  isDeleted: Scalars['Boolean'];
  isExpedited: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  location?: Maybe<Scalars['String']>;
  mailDate?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  printShopStatusFtp?: Maybe<PrintShopStatus>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedByUser?: Maybe<User>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type AttachmentDtoInput = {
  attachmentCategoryId?: InputMaybe<Scalars['Int']>;
  attachmentId: Scalars['Int'];
  attachmentIsDeleted: Scalars['Boolean'];
  attachmentName: Scalars['String'];
  attachmentPathTemp?: InputMaybe<Scalars['String']>;
};

export type AttachmentGroupActionValue = {
  __typename?: 'AttachmentGroupActionValue';
  attachment: Attachment;
  createdByUser: User;
  createdOn: Scalars['DateTime'];
};

export type AttachmentGroupOrderableOptions = {
  __typename?: 'AttachmentGroupOrderableOptions';
  codeLabel: Scalars['String'];
};

export type AttributeComparison = {
  __typename?: 'AttributeComparison';
  comparisonType?: Maybe<ComparisonType>;
  fieldName?: Maybe<Scalars['String']>;
  validationMessage?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type AttributeType =
  | BoolAttributeType
  | DateAttributeType
  | DateTimeAttributeType
  | ExpressionAttributeType
  | FloatAttributeType
  | IntAttributeType
  | LookupAttributeType
  | ReferenceAttributeType
  | TextAttributeType;

export type AuthTurnAroundTimeModel = {
  __typename?: 'AuthTurnAroundTimeModel';
  careSiteId: Scalars['Int'];
  careSiteName?: Maybe<Scalars['String']>;
  greaterThanThree: Scalars['Int'];
  lessThanThree: Scalars['Int'];
  lessThanTwo: Scalars['Int'];
  lessThanZero: Scalars['Int'];
};

export type AutoAssignInputModelInput = {
  episodeId: Scalars['Int'];
  episodeNumber: Scalars['String'];
  workflowInstanceId: Scalars['String'];
};

export enum AutoAssignStatus {
  AssignmentFailed = 'ASSIGNMENT_FAILED',
  Failed = 'FAILED',
  NoRoleMapping = 'NO_ROLE_MAPPING',
  Success = 'SUCCESS',
}

export enum AutoBalancerType {
  Episode = 'EPISODE',
  None = 'NONE',
  Patient = 'PATIENT',
}

export type AutobalancerComponent = {
  __typename?: 'AutobalancerComponent';
  autocomplete?: Maybe<Scalars['Boolean']>;
  color?: Maybe<Scalars['String']>;
  componentType: Scalars['String'];
  displayOnlyWhenSelected: Scalars['Boolean'];
  id: Scalars['String'];
  isDrawableOnFinish: Scalars['Boolean'];
  isReadonlyAfterFinishSet?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  readonlyAfterFinish?: Maybe<Scalars['Boolean']>;
  required: Scalars['Boolean'];
  style?: Maybe<Scalars['String']>;
  teamDisplayValue?: Maybe<Scalars['String']>;
  teamIdSelected?: Maybe<Scalars['Int']>;
  type?: Maybe<AutoBalancerType>;
  uniqueID: Scalars['String'];
  userDefinedId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  width: Scalars['Int'];
};

export type BaseComponent =
  | ActionButtonComponent
  | AutobalancerComponent
  | CheckboxRadioButtonComponent
  | ClipToolComponent
  | ComputedValueComponent
  | DateComponent
  | DateTimeComponent
  | DropdownComponent
  | EntityAttributeComponent
  | ExpressionComponent
  | ExternalUrlComponent
  | FixedTextComponent
  | ReminderComponent
  | UserFreeTextComponent;

export type BaseReminder = IAuditable & {
  __typename?: 'BaseReminder';
  comment?: Maybe<Scalars['String']>;
  createdBy: Scalars['Int'];
  createdByUser?: Maybe<User>;
  createdOn?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['DateTime']>;
  daysLeft: Scalars['Int'];
  daysOverdue: Scalars['Int'];
  dueDateTimeType?: Maybe<DueDateTimeType>;
  episode?: Maybe<Episode>;
  id: Scalars['Int'];
  importance?: Maybe<ReminderImportance>;
  importanceId?: Maybe<Scalars['Int']>;
  isCompleted?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isDueDateTimeUtc?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isNew?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
  owner?: Maybe<User>;
  reason?: Maybe<LookupValue>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedByUser?: Maybe<User>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type BoolAttributeType = {
  __typename?: 'BoolAttributeType';
  dataType?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  entityName: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type BoundingBox = {
  __typename?: 'BoundingBox';
  height: Scalars['Float'];
  left: Scalars['Float'];
  top: Scalars['Float'];
  width: Scalars['Float'];
};

export type CarePlanActionValue = {
  __typename?: 'CarePlanActionValue';
  carePlanEndDate?: Maybe<Scalars['DateTime']>;
  carePlanStartDate?: Maybe<Scalars['DateTime']>;
  carePlanState?: Maybe<CarePlanState>;
  note?: Maybe<Scalars['String']>;
  outcome?: Maybe<ILookupValue>;
  priority?: Maybe<ILookupValue>;
  problem: Problem;
  progress?: Maybe<ILookupValue>;
};

export type CarePlanOrderableOptions = {
  __typename?: 'CarePlanOrderableOptions';
  goal?: Maybe<Scalars['String']>;
  showDescription: Scalars['Boolean'];
  showGoal: Scalars['Boolean'];
  showPriority: Scalars['Boolean'];
  showProgress: Scalars['Boolean'];
};

export enum CarePlanState {
  Closed = 'CLOSED',
  Opened = 'OPENED',
}

export type CareSite = IAuditable &
  ILookupValue & {
    __typename?: 'CareSite';
    createdBy: Scalars['Int'];
    createdByUser?: Maybe<User>;
    createdOn?: Maybe<Scalars['DateTime']>;
    defaultPayor?: Maybe<LookupValue>;
    id: Scalars['Int'];
    isDeleted?: Maybe<Scalars['Boolean']>;
    name?: Maybe<Scalars['String']>;
    printOnFinish?: Maybe<Scalars['Boolean']>;
    printShop?: Maybe<Scalars['Boolean']>;
    printShopFtp?: Maybe<Scalars['Boolean']>;
    supportInformation?: Maybe<Scalars['String']>;
    timeZone?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    updatedBy?: Maybe<Scalars['Int']>;
    updatedByUser?: Maybe<User>;
    updatedOn?: Maybe<Scalars['DateTime']>;
  };

export type CareSiteCreatedByUserArgs = {
  userId?: InputMaybe<Scalars['Int']>;
};

export type CareSiteUpdatedByUserArgs = {
  userId?: InputMaybe<Scalars['Int']>;
};

export type CareSiteFaxNumberViewModel = {
  __typename?: 'CareSiteFaxNumberViewModel';
  faxId: Scalars['Int'];
  faxLabel: Scalars['String'];
  faxNumber: Scalars['String'];
};

export type CareTeamMemberDtoInput = {
  isOwner: Scalars['Boolean'];
  note?: InputMaybe<Scalars['String']>;
  userId: Scalars['Int'];
};

export type CaseEpisodeViewModel = {
  __typename?: 'CaseEpisodeViewModel';
  anyProgress?: Maybe<Scalars['Boolean']>;
  careStage?: Maybe<Scalars['String']>;
  counter?: Maybe<CounterClockColumnViewModel>;
  createdOn?: Maybe<Scalars['DateTime']>;
  engagedDate?: Maybe<Scalars['Date']>;
  episodeNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  owner?: Maybe<Scalars['String']>;
  program?: Maybe<Scalars['String']>;
  referralDate?: Maybe<Scalars['Date']>;
  state?: Maybe<EpisodeState>;
  tat?: Maybe<TatClockColumnViewModel>;
  timeZone?: Maybe<Scalars['String']>;
  topReminder?: Maybe<BaseReminder>;
};

export type ChangeReminderStateDtoInput = {
  reminderId: Scalars['Int'];
  state: ReminderStateDto;
};

export enum CheckExistingEpisodeStatus {
  NoOpenEpisodes = 'NO_OPEN_EPISODES',
  OpenEpisodes = 'OPEN_EPISODES',
  SelectedEpisodeClosedButOtherEpisodeOpen = 'SELECTED_EPISODE_CLOSED_BUT_OTHER_EPISODE_OPEN',
  SelectedEpisodeIsOpen = 'SELECTED_EPISODE_IS_OPEN',
}

export type CheckNewEpisodeResult = {
  __typename?: 'CheckNewEpisodeResult';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<CheckNewEpisodeStatus>;
};

export enum CheckNewEpisodeStatus {
  Continue = 'CONTINUE',
  Stop = 'STOP',
  Warning = 'WARNING',
}

export type CheckPriorAuthorizationActionContextInput = {
  codes: Array<InputMaybe<Scalars['String']>>;
  reqEndDate: Scalars['DateTime'];
  reqStartDate: Scalars['DateTime'];
};

export type CheckPriorAuthorizationResult = {
  __typename?: 'CheckPriorAuthorizationResult';
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  externalURL?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isPARequired: Scalars['Boolean'];
  notificationMessage?: Maybe<Scalars['String']>;
  primaryMessage?: Maybe<Scalars['String']>;
  priorAuthRequirement?: Maybe<PaRequirement>;
  secondaryMessage?: Maybe<Scalars['String']>;
};

export type CheckboxRadioButtonComponent = {
  __typename?: 'CheckboxRadioButtonComponent';
  checked: Scalars['Boolean'];
  clearAfterFinish: Scalars['Boolean'];
  color?: Maybe<Scalars['String']>;
  componentType: Scalars['String'];
  displayOnlyWhenSelected: Scalars['Boolean'];
  editable: Scalars['Boolean'];
  fieldName?: Maybe<Scalars['String']>;
  fieldValue?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['String']>;
  groupRequired: Scalars['Boolean'];
  hasValue: Scalars['Boolean'];
  hidden: Scalars['Boolean'];
  id: Scalars['String'];
  labelSource?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  problem?: Maybe<Scalars['String']>;
  required: Scalars['Boolean'];
  score?: Maybe<Scalars['String']>;
  scoringGroup?: Maybe<Scalars['String']>;
  selected: Scalars['Boolean'];
  style?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  uniqueID: Scalars['String'];
  userDefinedId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  width: Scalars['Int'];
};

export type Checklist = IAuditable & {
  __typename?: 'Checklist';
  attachments?: Maybe<Array<Maybe<Attachment>>>;
  author?: Maybe<Scalars['Int']>;
  authorUser?: Maybe<User>;
  carePlan?: Maybe<Checklist>;
  createdBy: Scalars['Int'];
  createdByUser?: Maybe<User>;
  createdOn?: Maybe<Scalars['DateTime']>;
  entryStep?: Maybe<Scalars['String']>;
  episode?: Maybe<Episode>;
  finishedBy?: Maybe<Scalars['Int']>;
  finishedOn?: Maybe<Scalars['DateTime']>;
  hasDocument?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  isCrawled?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isUpdated?: Maybe<Scalars['Boolean']>;
  patient?: Maybe<Patient>;
  problems?: Maybe<Array<Maybe<ChecklistProblem>>>;
  reasonForNote?: Maybe<LookupValue>;
  sourceChecklist?: Maybe<Checklist>;
  status?: Maybe<ChecklistStatus>;
  timesPrintRequested?: Maybe<Scalars['Int']>;
  type?: Maybe<ChecklistType>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedByUser?: Maybe<User>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type ChecklistAuthorUserArgs = {
  userId?: InputMaybe<Scalars['Int']>;
};

export type ChecklistActionComponentDataInput = {
  checklistId?: InputMaybe<Scalars['Int']>;
  componentId: Scalars['String'];
  groupId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type ChecklistActionDataInput = {
  data: Array<InputMaybe<ChecklistActionComponentDataInput>>;
  id: Scalars['Int'];
  index: Scalars['Int'];
  isRepeated: Scalars['Boolean'];
  isSelected: Scalars['Boolean'];
};

export type ChecklistCategory = {
  __typename?: 'ChecklistCategory';
  id: Scalars['ID'];
  isCollapsed: Scalars['Boolean'];
  isReadOnly: Scalars['Boolean'];
  isSubheader: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  items: Array<ChecklistItem>;
  name: Scalars['String'];
  shortName: Scalars['String'];
  subCategories: Array<ChecklistCategory>;
};

export type ChecklistItem =
  | ChecklistItemAssociatedClaims
  | ChecklistItemAssociatedEpisodes
  | ChecklistItemAttachmentGroup
  | ChecklistItemCarePlans
  | ChecklistItemConditions
  | ChecklistItemDuplicateCheck
  | ChecklistItemHealthServices
  | ChecklistItemLetterSummary
  | ChecklistItemMedicalCodes
  | ChecklistItemMedications
  | ChecklistItemNote
  | ChecklistItemOrderable
  | ChecklistItemPriorAuthCodes
  | ChecklistItemReminder
  | ChecklistItemSubheader;

export type ChecklistItemAssociatedClaims = {
  __typename?: 'ChecklistItemAssociatedClaims';
  acValue: Array<AssociatedClaimActionValue>;
  isSelected: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  options: AssociatedClaimOrderableOptions;
  orderSet: OrderSetViewModel;
  orderableId: Scalars['ID'];
  type: Scalars['String'];
  uid: Scalars['ID'];
};

export type ChecklistItemAssociatedEpisodes = {
  __typename?: 'ChecklistItemAssociatedEpisodes';
  actionValue?: Maybe<Array<Maybe<AssociatedEpisodeActionValue>>>;
  isSelected: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  options: AssociatedEpisodeOrderableOptions;
  orderSet: OrderSetViewModel;
  orderableId: Scalars['ID'];
  type: Scalars['String'];
  uid: Scalars['ID'];
};

export type ChecklistItemAttachmentGroup = {
  __typename?: 'ChecklistItemAttachmentGroup';
  agValue?: Maybe<Array<Maybe<AttachmentGroupActionValue>>>;
  isSelected: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  options: AttachmentGroupOrderableOptions;
  orderSet: OrderSetViewModel;
  orderableId: Scalars['ID'];
  type: Scalars['String'];
  uid: Scalars['ID'];
};

export type ChecklistItemCarePlans = {
  __typename?: 'ChecklistItemCarePlans';
  cpValue: CarePlanActionValue;
  isSelected: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  options: CarePlanOrderableOptions;
  orderSet: OrderSetViewModel;
  orderableId: Scalars['ID'];
  type: Scalars['String'];
  uid: Scalars['ID'];
};

export type ChecklistItemConditions = {
  __typename?: 'ChecklistItemConditions';
  isSelected: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  orderSet: OrderSetViewModel;
  orderableId: Scalars['ID'];
  type: Scalars['String'];
  uid: Scalars['ID'];
};

export type ChecklistItemDuplicateCheck = {
  __typename?: 'ChecklistItemDuplicateCheck';
  dcValue?: Maybe<DuplicateCheckActionValue>;
  isSelected: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  options: DuplicateCheckOrderableOptions;
  orderSet: OrderSetViewModel;
  orderableId: Scalars['ID'];
  type: Scalars['String'];
  uid: Scalars['ID'];
};

export type ChecklistItemHealthServices = {
  __typename?: 'ChecklistItemHealthServices';
  conditions: Array<ConditionViewModel>;
  hSValue?: Maybe<HealthServiceCodesActionModel>;
  isSelected: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  options: HealthServiceCodesOrderableOptions;
  orderSet: OrderSetViewModel;
  orderableId: Scalars['ID'];
  type: Scalars['String'];
  uid: Scalars['ID'];
};

export type ChecklistItemLetterSummary = {
  __typename?: 'ChecklistItemLetterSummary';
  isSelected: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  lsValue?: Maybe<Array<Maybe<LetterSummaryActionValue>>>;
  options: LetterSummaryOrderableOptions;
  orderSet: OrderSetViewModel;
  orderableId: Scalars['ID'];
  type: Scalars['String'];
  uid: Scalars['ID'];
};

export type ChecklistItemMedicalCodes = {
  __typename?: 'ChecklistItemMedicalCodes';
  isSelected: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  options: MedicationCodesOrderableOptions;
  orderSet: OrderSetViewModel;
  orderableId: Scalars['ID'];
  type: Scalars['String'];
  uid: Scalars['ID'];
  value?: Maybe<Array<Maybe<MedicationCodesActionValue>>>;
};

export type ChecklistItemMedications = {
  __typename?: 'ChecklistItemMedications';
  isSelected: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  orderSet: OrderSetViewModel;
  orderableId: Scalars['ID'];
  type: Scalars['String'];
  uid: Scalars['ID'];
};

export type ChecklistItemNote = {
  __typename?: 'ChecklistItemNote';
  isSelected: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  orderSet: OrderSetViewModel;
  orderableId: Scalars['ID'];
  preview?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  uid: Scalars['ID'];
};

export type ChecklistItemOrderable = {
  __typename?: 'ChecklistItemOrderable';
  conditions: Array<ConditionViewModel>;
  createdBy?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  hasRepeater: Scalars['Boolean'];
  isReadOnly: Scalars['Boolean'];
  isSelected: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  lines: Array<ComponentsLine>;
  listStyleType: ListStyleType;
  orderSet: OrderSetViewModel;
  orderableId: Scalars['ID'];
  qualityMeasure?: Maybe<QualityMeasure>;
  repeaterOptions?: Maybe<RepeaterOrderableOptions>;
  score?: Maybe<Scalars['Float']>;
  scoringSystemGroup?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  uid: Scalars['ID'];
};

export type ChecklistItemPriorAuthCodes = {
  __typename?: 'ChecklistItemPriorAuthCodes';
  isSelected: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  options: PriorAuthCodesOrderableOptions;
  orderSet: OrderSetViewModel;
  orderableId: Scalars['ID'];
  pAValue?: Maybe<PriorAuthCodesActionModel>;
  type: Scalars['String'];
  uid: Scalars['ID'];
};

export type ChecklistItemReminder = {
  __typename?: 'ChecklistItemReminder';
  isSelected: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  orderSet: OrderSetViewModel;
  orderableId: Scalars['ID'];
  preview?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  uid: Scalars['ID'];
};

export type ChecklistItemSubheader = {
  __typename?: 'ChecklistItemSubheader';
  isSelected: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  orderSet: OrderSetViewModel;
  orderableId: Scalars['ID'];
  preview?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  uid: Scalars['ID'];
};

export type ChecklistLookupViewModel = {
  __typename?: 'ChecklistLookupViewModel';
  enabledValues?: Maybe<Array<Scalars['Int']>>;
  id: Scalars['Int'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  values: Array<LookupValue>;
};

export enum ChecklistOperation {
  Amend = 'AMEND',
  Continue = 'CONTINUE',
  Delete = 'DELETE',
  Fax = 'FAX',
  Print = 'PRINT',
  QuickNoteUpdate = 'QUICK_NOTE_UPDATE',
  Update = 'UPDATE',
  View = 'VIEW',
}

export type ChecklistOrderSetRowView = {
  __typename?: 'ChecklistOrderSetRowView';
  backcolor?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  forecolor?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ChecklistProblem = {
  __typename?: 'ChecklistProblem';
  carePlanEndDate?: Maybe<Scalars['Date']>;
  carePlanStartDate?: Maybe<Scalars['Date']>;
  carePlanState?: Maybe<CarePlanState>;
  checklist?: Maybe<Checklist>;
  id: Scalars['Int'];
  note?: Maybe<Scalars['String']>;
  outcome?: Maybe<ILookupValue>;
  priority?: Maybe<ILookupValue>;
  problem?: Maybe<Problem>;
  progress?: Maybe<ILookupValue>;
  sortIndex?: Maybe<Scalars['Int']>;
};

export type ChecklistProblemViewModel = {
  __typename?: 'ChecklistProblemViewModel';
  carePlanState?: Maybe<CarePlanState>;
  id: Scalars['ID'];
  inCarePlan: Scalars['Boolean'];
  isReadOnly: Scalars['Boolean'];
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  sortIndex: Scalars['Int'];
};

export enum ChecklistStatus {
  Amended = 'AMENDED',
  Completed = 'COMPLETED',
  InProcess = 'IN_PROCESS',
}

export enum ChecklistType {
  CarePlan = 'CARE_PLAN',
  CarePlanVersion = 'CARE_PLAN_VERSION',
  None = 'NONE',
  QuickNote = 'QUICK_NOTE',
  UserConfiguredChecklist = 'USER_CONFIGURED_CHECKLIST',
}

export type ChecklistViewModel = {
  __typename?: 'ChecklistViewModel';
  amended: Scalars['Boolean'];
  attributeTypes: Array<AttributeType>;
  author: User;
  authorizationStatus?: Maybe<LookupValue>;
  bigTableValues: Array<Entity>;
  canAmend: Scalars['Boolean'];
  carePlan?: Maybe<ChecklistViewModel>;
  carePlanProblems?: Maybe<Array<ChecklistProblemViewModel>>;
  categories: Array<ChecklistCategory>;
  createdOn: Scalars['DateTime'];
  entities: Array<Entity>;
  episodeDocumentsCount: Scalars['Int'];
  episodeId?: Maybe<Scalars['Int']>;
  episodeNumber?: Maybe<Scalars['String']>;
  filterState?: Maybe<PatientCarePlanStatusFilterViewModel>;
  finishedOn?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  includeCarePlan: Scalars['Boolean'];
  isFaxEpisodeDocument: Scalars['Boolean'];
  lettersIsAvailableForUser: Scalars['Boolean'];
  lookups: Array<ChecklistLookupViewModel>;
  mustCloseCarePlan: Scalars['Boolean'];
  owner: User;
  patientId: Scalars['Int'];
  reasonForNote?: Maybe<LookupValue>;
  scenarioId?: Maybe<Scalars['Int']>;
  scenarioName?: Maybe<Scalars['String']>;
  status: ChecklistStatus;
  traceUserEnabled: Scalars['Boolean'];
  type: ChecklistType;
  updated: Scalars['Boolean'];
  updatedBy?: Maybe<User>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export enum ChecklistViewType {
  AppealGrivanceTab = 'APPEAL_GRIVANCE_TAB',
  EpisodeTab = 'EPISODE_TAB',
  HistoryTab = 'HISTORY_TAB',
  TimelineTab = 'TIMELINE_TAB',
}

export type ClipToolComponent = {
  __typename?: 'ClipToolComponent';
  attachmentId?: Maybe<Scalars['Int']>;
  buttonLabel?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  componentType: Scalars['String'];
  displayOnlyWhenSelected: Scalars['Boolean'];
  fileName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isDrawableOnFinish: Scalars['Boolean'];
  maxHeight?: Maybe<Scalars['Int']>;
  maxWidth?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  required: Scalars['Boolean'];
  style?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  typeInsert?: Maybe<ClipToolTypeInsert>;
  uniqueID: Scalars['String'];
  userDefinedId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  width: Scalars['Int'];
};

export enum ClipToolTypeInsert {
  Ocr = 'OCR',
  Picture = 'PICTURE',
}

export type Clock = IAuditable & {
  __typename?: 'Clock';
  clockType?: Maybe<Scalars['String']>;
  createdBy: Scalars['Int'];
  createdByUser?: Maybe<User>;
  createdOn?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  startDateTime?: Maybe<Scalars['DateTime']>;
  state?: Maybe<ClockState>;
  stopDateTime?: Maybe<Scalars['DateTime']>;
  turnAroundTime?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedByUser?: Maybe<User>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export enum ClockEventType {
  Complete = 'COMPLETE',
  Create = 'CREATE',
  Extend = 'EXTEND',
  Pause = 'PAUSE',
  Recalculate = 'RECALCULATE',
  Reset = 'RESET',
  Restart = 'RESTART',
}

export type ClockHistoryViewModel = {
  __typename?: 'ClockHistoryViewModel';
  action: ClockEventType;
  createdByUser: User;
  createdOn: Scalars['DateTime'];
};

export enum ClockState {
  Active = 'ACTIVE',
  Completed = 'COMPLETED',
  Error = 'ERROR',
  Paused = 'PAUSED',
}

export type CloseEpisodeResult = {
  __typename?: 'CloseEpisodeResult';
  message?: Maybe<Scalars['String']>;
  reminderCount?: Maybe<Scalars['Int']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ColumnWidthModelInput = {
  columnName: Scalars['String'];
  tabIndex: Scalars['Int'];
  width: Scalars['Int'];
};

export enum ComparisonType {
  EqualTo = 'EQUAL_TO',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  None = 'NONE',
}

export type ComponentsLine = {
  __typename?: 'ComponentsLine';
  components: Array<BaseComponent>;
};

export type ComputedValueComponent = {
  __typename?: 'ComputedValueComponent';
  additionalValue?: Maybe<Scalars['Int']>;
  attributeForUpdate?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  componentType: Scalars['String'];
  computedValueType?: Maybe<ComputedValueType>;
  dangerValue?: Maybe<Scalars['Int']>;
  daysType?: Maybe<TimerDaysType>;
  displayOnlyWhenSelected: Scalars['Boolean'];
  fieldId?: Maybe<Scalars['String']>;
  fieldSourceId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  idGenerator?: Maybe<Scalars['String']>;
  isDrawableOnFinish: Scalars['Boolean'];
  mode?: Maybe<TimerValueType>;
  name?: Maybe<Scalars['String']>;
  required: Scalars['Boolean'];
  scoringGroupId?: Maybe<Scalars['Int']>;
  showTargetDate?: Maybe<Scalars['Boolean']>;
  style?: Maybe<Scalars['String']>;
  targetDate?: Maybe<Scalars['DateTime']>;
  timeScale?: Maybe<TimerScaleType>;
  uniqueID: Scalars['String'];
  userDefinedId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  width: Scalars['Int'];
};

export enum ComputedValueType {
  Autoid = 'AUTOID',
  Basicscoring = 'BASICSCORING',
  Timer = 'TIMER',
}

export type ConditionDtoInput = {
  description: Scalars['String'];
  id: Scalars['Int'];
  note?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Boolean']>;
};

export enum ConditionTarget {
  Action = 'ACTION',
  Category = 'CATEGORY',
}

export type ConditionViewModel = {
  __typename?: 'ConditionViewModel';
  categoryId?: Maybe<Scalars['ID']>;
  conditionValue?: Maybe<Scalars['String']>;
  orderableId?: Maybe<Scalars['ID']>;
  targetType: ConditionTarget;
  type: OrderableDependentConditionType;
  value?: Maybe<Scalars['String']>;
};

export type ContinueMozartWorkflowRequestInput = {
  action?: InputMaybe<Scalars['String']>;
  actionId: Scalars['UUID'];
  episodeId: Scalars['Int'];
  patientId: Scalars['Int'];
  stepId: Scalars['UUID'];
};

export type CounterClockColumnViewModel = {
  __typename?: 'CounterClockColumnViewModel';
  startDateTime?: Maybe<Scalars['DateTime']>;
  state?: Maybe<ClockState>;
  timeElapsed?: Maybe<Scalars['String']>;
};

export type CounterViewModel = {
  __typename?: 'CounterViewModel';
  additionalTime?: Maybe<Scalars['String']>;
  clockType: Scalars['String'];
  dateOnly: Scalars['Boolean'];
  drivers: Array<Scalars['String']>;
  history: Array<ClockHistoryViewModel>;
  startDateTime: Scalars['DateTime'];
  state: ClockState;
  timeElapsed?: Maybe<Scalars['String']>;
};

export type CreateAndViewWorkflowChecklistResult = {
  __typename?: 'CreateAndViewWorkflowChecklistResult';
  checklist?: Maybe<ChecklistViewModel>;
  message: Scalars['String'];
};

export type CreateChecklistModelInput = {
  attachEpisodeId?: InputMaybe<Scalars['Int']>;
  episodeId?: InputMaybe<Scalars['Int']>;
  isNewEpisode?: InputMaybe<Scalars['Boolean']>;
  patientId: Scalars['Int'];
  problemIds?: InputMaybe<Array<InputMaybe<KeyValuePairOfInt32AndInt32Input>>>;
  scenarioId: Scalars['Int'];
  sessionId: Scalars['String'];
};

export type CreateChecklistResultModel = {
  __typename?: 'CreateChecklistResultModel';
  checklistId?: Maybe<Scalars['Int']>;
  status?: Maybe<CreateChecklistStatus>;
};

export enum CreateChecklistStatus {
  Locked = 'LOCKED',
  Success = 'SUCCESS',
}

export type CreateMozartChecklistResult = {
  __typename?: 'CreateMozartChecklistResult';
  checklistId?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  shouldRetry: Scalars['Boolean'];
};

export type CreateOrUpdateCarePlanActionContextInput = {
  addToCarePlan: Scalars['Boolean'];
  checklistId?: InputMaybe<Scalars['Int']>;
  note?: InputMaybe<Scalars['String']>;
  patientId: Scalars['Int'];
  problemId: Scalars['Int'];
};

export type DateAttributeType = {
  __typename?: 'DateAttributeType';
  dataType?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  entityName: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type DateComponent = {
  __typename?: 'DateComponent';
  color?: Maybe<Scalars['String']>;
  componentType: Scalars['String'];
  daysAfterLimit?: Maybe<Scalars['Int']>;
  daysBeforeLimit?: Maybe<Scalars['Int']>;
  displayOnlyWhenSelected: Scalars['Boolean'];
  id: Scalars['String'];
  linkMessage?: Maybe<Scalars['String']>;
  mirrorGroup?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  oneWaySrc?: Maybe<Scalars['String']>;
  pastDatesNotAllowed: Scalars['Boolean'];
  primaryComparison?: Maybe<AttributeComparison>;
  required: Scalars['Boolean'];
  secondaryComparison?: Maybe<AttributeComparison>;
  style?: Maybe<Scalars['String']>;
  uniqueID: Scalars['String'];
  useCurrentDate: Scalars['Boolean'];
  userDefinedId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  width: Scalars['Int'];
};

export type DateTimeAttributeType = {
  __typename?: 'DateTimeAttributeType';
  dataType?: Maybe<Scalars['String']>;
  displayFormat: Scalars['String'];
  displayName: Scalars['String'];
  entityName: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type DateTimeComponent = {
  __typename?: 'DateTimeComponent';
  color?: Maybe<Scalars['String']>;
  componentType: Scalars['String'];
  daysAfterLimit?: Maybe<Scalars['Int']>;
  daysBeforeLimit?: Maybe<Scalars['Int']>;
  displayOnlyWhenSelected: Scalars['Boolean'];
  id: Scalars['String'];
  linkMessage?: Maybe<Scalars['String']>;
  mirrorGroup?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  oneWaySrc?: Maybe<Scalars['String']>;
  pastDatesNotAllowed: Scalars['Boolean'];
  primaryComparison?: Maybe<AttributeComparison>;
  required: Scalars['Boolean'];
  secondaryComparison?: Maybe<AttributeComparison>;
  style?: Maybe<Scalars['String']>;
  uniqueID: Scalars['String'];
  useCurrentDate: Scalars['Boolean'];
  useSeconds: Scalars['Boolean'];
  userDefinedId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  width: Scalars['Int'];
};

export enum DayType {
  Business = 'BUSINESS',
  Calendar = 'CALENDAR',
}

export type DictionaryValue = IAuditable & {
  __typename?: 'DictionaryValue';
  createdBy: Scalars['Int'];
  createdOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
};

export type DownloadFileDto = {
  __typename?: 'DownloadFileDto';
  content?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
};

export type DropdownComponent = {
  __typename?: 'DropdownComponent';
  autocomplete: Scalars['Boolean'];
  choices?: Maybe<Array<Scalars['String']>>;
  color?: Maybe<Scalars['String']>;
  componentType: Scalars['String'];
  displayOnlyWhenSelected: Scalars['Boolean'];
  displayValue?: Maybe<Scalars['String']>;
  dropDownType: DropdownType;
  entityName?: Maybe<Scalars['String']>;
  fieldName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isBigTable: Scalars['Boolean'];
  linkMessage?: Maybe<Scalars['String']>;
  lookupId?: Maybe<Scalars['String']>;
  mirrorGroup?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  oneWaySrc?: Maybe<Scalars['String']>;
  required: Scalars['Boolean'];
  style?: Maybe<Scalars['String']>;
  uniqueID: Scalars['String'];
  userDefinedId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  width: Scalars['Int'];
};

export enum DropdownType {
  Custom = 'CUSTOM',
  Entity = 'ENTITY',
  Lookup = 'LOOKUP',
}

export enum DueDateTimeType {
  Date = 'DATE',
  DateTime = 'DATE_TIME',
}

export type DummyProvider = {
  __typename?: 'DummyProvider';
  address?: Maybe<Scalars['String']>;
  addressId?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
  nPI?: Maybe<Scalars['String']>;
  par?: Maybe<FoundProviderStatus>;
  providerAddressUiId?: Maybe<Scalars['String']>;
  providerId?: Maybe<Scalars['String']>;
};

export type DuplicateCheckActionValue = {
  __typename?: 'DuplicateCheckActionValue';
  duplicateFound?: Maybe<Scalars['Boolean']>;
  duplicateFoundConfirmation?: Maybe<Scalars['String']>;
};

export type DuplicateCheckOrderableOptions = {
  __typename?: 'DuplicateCheckOrderableOptions';
  duplicateFound: Scalars['String'];
  duplicateFoundConfirmation: Scalars['String'];
};

export type EmailDto = {
  __typename?: 'EmailDto';
  address?: Maybe<Scalars['String']>;
  isPrimary: Scalars['Boolean'];
  type: Scalars['String'];
};

export type EmailDtoInput = {
  address?: InputMaybe<Scalars['String']>;
  isPrimary: Scalars['Boolean'];
  type: Scalars['String'];
};

export type EntitiesInfoInput = {
  episodeIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  patientIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  values?: InputMaybe<Array<InputMaybe<KeyValuePairOfStringAndStringInput>>>;
};

export type EntitiesQuery = {
  __typename?: 'EntitiesQuery';
  checkIsPatientActive: Scalars['Boolean'];
  checkNewEpisode: CheckNewEpisodeResult;
  checkOpenedEpisode?: Maybe<CheckExistingEpisodeStatus>;
  checkPriorAuthorization?: Maybe<Array<Maybe<CheckPriorAuthorizationResult>>>;
  downloadFile: DownloadFileDto;
  findScenarios?: Maybe<Array<Scenario>>;
  getAccountUsers?: Maybe<Array<Maybe<User>>>;
  getAdvancedSearchRequiredFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  getAllowedFileTypesAndSize: AllowedFileTypesAndSizeModel;
  getAttributeDefinitions?: Maybe<Array<EntityAttributeDef>>;
  getAttributeLookups?: Maybe<Array<Maybe<Lookup>>>;
  getAttributes?: Maybe<Array<Maybe<EntityAttribute>>>;
  getAuthTurnAroundTime?: Maybe<Array<Maybe<AuthTurnAroundTimeModel>>>;
  getAutoAssignEpisode?: Maybe<EpisodeAutoAssign>;
  getCarePlan?: Maybe<PatientCarePlanViewModel>;
  getCarePlanProblemsByEpisode: Array<Problem>;
  getCareSite?: Maybe<CareSite>;
  getCasesTabData: PatientCasesViewModel;
  getChecklist: ChecklistViewModel;
  getChecklists: Array<ChecklistViewModel>;
  getChecklistsByClinicalEpisode?: Maybe<Array<PatientChecklistViewModel>>;
  getChecklistsByNonClinicalEpisode?: Maybe<Array<PatientChecklistViewModel>>;
  getClaims?: Maybe<Array<Maybe<PatientClaimDto>>>;
  getCmUserCaseLoadAndReminderCount: Array<UserCaseLoadAndReminderCount>;
  getDummyProvider?: Maybe<DummyProvider>;
  getDuplicateEpisodes?: Maybe<PatientEpisodesViewModel>;
  getEnabledPopulations?: Maybe<Array<Maybe<ILookupValue>>>;
  getEntities?: Maybe<Array<Maybe<Entity>>>;
  getEpisode?: Maybe<Episode>;
  getEpisodeAttachments: Array<Attachment>;
  getEpisodeBalancerQueueRecentCriteria?: Maybe<EpisodeBalancerCriteria>;
  getEpisodeClaims: Array<PatientClaimDto>;
  getEpisodeCounter?: Maybe<CounterViewModel>;
  getEpisodeDocuments?: Maybe<Array<Maybe<EpisodesTabChecklist>>>;
  getEpisodeReminders: Array<BaseReminder>;
  getEpisodeSummary?: Maybe<EpisodeSummary>;
  getEpisodeSummaryHistory: Array<EpisodeSummary>;
  getEpisodeTabData?: Maybe<PatientEpisodesWithMozartInfoViewModel>;
  getEpisodeTabModel?: Maybe<PatientEpisodesTabModel>;
  getEpisodeTabTypes?: Maybe<Array<Maybe<EpisodeTabTypes>>>;
  getEpisodeTat?: Maybe<TatViewModel>;
  getEpisodeTypes: Array<EpisodeType>;
  getEpisodeWorkflowMdStepCount?: Maybe<
    Array<Maybe<EpisodeWorkflowMdStepCountViewModel>>
  >;
  getExtractedTextFromAttachment?: Maybe<Array<TextExtractionModel>>;
  getFaxDialogModel: FaxDialogViewModel;
  getFaxParameters?: Maybe<FaxParameters>;
  getFormattedNoteAutocompleteText: Scalars['String'];
  getHomeModel?: Maybe<HomePageModel>;
  getLetterDialogModel?: Maybe<LetterDialogModel>;
  getLetterSummary: Array<Attachment>;
  getLinkEpisodes?: Maybe<Array<Maybe<LinkEpisode>>>;
  getLookupValues?: Maybe<Array<LookupValue>>;
  getLookups?: Maybe<Array<Lookup>>;
  getMedicationReviewHistoryViewModel: Array<
    Maybe<MedicationReviewHistoryViewModel>
  >;
  getMedsTabViewModel: MedsTabViewModel;
  getMozartChecklist?: Maybe<GetMozartActionProcessResult>;
  getMozartEntityDiffS3?: Maybe<MozartDataResult>;
  getMozartOutOfTurnSteps?: Maybe<Array<WorkflowInstanceAvailableOotSteps>>;
  getMozartWorkFlowActionData?: Maybe<WorkFlowActionDataResult>;
  getMozartWorkflowActions?: Maybe<GetMozartWorkflowActionsResult>;
  getMozartWorkflowEpisodeAuthorizationStatus?: Maybe<GetWorkflowEpisodeAuthorizationStatusResult>;
  getMultiEditData?: Maybe<MultiEditModel>;
  getMultiPageAttachment: MultiPageAttachmentViewModel;
  getNoteAutocompleteEntries: Array<Maybe<NoteAutocomplete>>;
  getOpenedClinicalEpisode?: Maybe<Episode>;
  getOpenedEpisodeCarePlanProblems: Array<Problem>;
  getPatientAttachments: Array<Attachment>;
  getPatientById?: Maybe<Patient>;
  getPatientCareTeamTab: PatientCareTeamTabViewModel;
  getPatientContacts: PatientContactsViewModel;
  getPatientDetailsTabs?: Maybe<Array<Maybe<PatientDetailsTabDefinition>>>;
  getPatientFromAttachment?: Maybe<SuggestPatient>;
  getPatientInsurance?: Maybe<PatientInsuranceInfo>;
  getPatientInsuranceStatus?: Maybe<Scalars['Boolean']>;
  getPatientInsuranceTab: PatientInsuranceTabViewModel;
  getPatientOpIpAuthorizations?: Maybe<PatientOpIpAuthorizationsViewModel>;
  getPatientQuickNotes?: Maybe<Array<Maybe<EpisodesTabChecklist>>>;
  getPatientReminders: Array<BaseReminder>;
  getPatientRisk?: Maybe<PatientRisk>;
  getPatientRiskClinicClassifications?: Maybe<
    Array<Maybe<PatientRiskClinicalClassification>>
  >;
  getPatientRiskTab: PatientEditRiskTabViewModel;
  getPatientTags: Array<Tag>;
  getPatientTagsTab?: Maybe<Array<Maybe<PatientEditTagsTabViewModel>>>;
  getPatientTimelineData?: Maybe<TimelineResponse>;
  getPatientTimelineModel?: Maybe<TimelineTabModel>;
  getPharmacies: Array<Maybe<Pharmacy>>;
  getPharmacyById: Pharmacy;
  getPharmacySuggestions: Array<Maybe<Pharmacy>>;
  getProblemsList: Array<Maybe<Problem>>;
  getProviderAttributes?: Maybe<Array<Maybe<KeyValuePairOfStringAndString>>>;
  getProviderDetails: ProviderDetailsViewModel;
  getProviderSearchDialogModel?: Maybe<ProviderSearchDialogModel>;
  getRecentSearches: Array<Scalars['String']>;
  getReminderHistory?: Maybe<ReminderHistoryData>;
  getReminders?: Maybe<Array<BaseReminder>>;
  getRoles?: Maybe<Array<Maybe<Role>>>;
  getSystemValueByName?: Maybe<Scalars['String']>;
  getSystemValues?: Maybe<GetSystemValuesConnection>;
  getTabModel?: Maybe<HomeTabModel>;
  getTags?: Maybe<Array<Tag>>;
  getTopReminder?: Maybe<BaseReminder>;
  getUmRnDashboardCount: Array<UmRnDashboardCount>;
  getUserPreferences?: Maybe<User>;
  getUserProvidersSelectModel: GetUserProvidersSelectModel;
  getUserRoles: Array<UserRole>;
  loadQuickNote: ChecklistViewModel;
  searchClaims: PatientClaimDtoSearchResult;
  searchEpisodes: EpisodeSearchEpisodeResult;
  searchMedicationCodes?: Maybe<Array<Maybe<SearchResult>>>;
  searchPatients: Array<SearchPatientDialogPatientsViewModel>;
  searchProvider?: Maybe<ProviderSearchResult>;
  suggestEntities?: Maybe<Array<Maybe<ILookupValue>>>;
  suggestProviders: Array<SuggestProvider>;
};

export type EntitiesQueryCheckIsPatientActiveArgs = {
  patientId: Scalars['Int'];
};

export type EntitiesQueryCheckNewEpisodeArgs = {
  patientId: Scalars['Int'];
};

export type EntitiesQueryCheckOpenedEpisodeArgs = {
  episodeId: Scalars['Int'];
  patientId: Scalars['Int'];
};

export type EntitiesQueryCheckPriorAuthorizationArgs = {
  request?: InputMaybe<CheckPriorAuthorizationActionContextInput>;
};

export type EntitiesQueryDownloadFileArgs = {
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type EntitiesQueryFindScenariosArgs = {
  checklistId?: InputMaybe<Scalars['Int']>;
  episodeId?: InputMaybe<Scalars['Int']>;
  newEpisode?: InputMaybe<Scalars['Boolean']>;
  patientId?: InputMaybe<Scalars['Int']>;
};

export type EntitiesQueryGetAttributeDefinitionsArgs = {
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
  includeSystem?: InputMaybe<Scalars['Boolean']>;
};

export type EntitiesQueryGetAttributesArgs = {
  entityId?: InputMaybe<Scalars['Int']>;
  entityName?: InputMaybe<Scalars['String']>;
};

export type EntitiesQueryGetAuthTurnAroundTimeArgs = {
  careSiteId?: InputMaybe<Scalars['Int']>;
  defaultAuthorization?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export type EntitiesQueryGetCarePlanArgs = {
  filterModel: PatientCarePlanFilterModelInput;
};

export type EntitiesQueryGetCarePlanProblemsByEpisodeArgs = {
  episodeId: Scalars['Int'];
};

export type EntitiesQueryGetCareSiteArgs = {
  caresiteId: Scalars['String'];
};

export type EntitiesQueryGetCasesTabDataArgs = {
  request?: InputMaybe<PatientCasesFilterModelInput>;
};

export type EntitiesQueryGetChecklistArgs = {
  checklistId: Scalars['Int'];
};

export type EntitiesQueryGetChecklistsArgs = {
  checklistIds: Array<Scalars['Int']>;
};

export type EntitiesQueryGetChecklistsByClinicalEpisodeArgs = {
  patientId: Scalars['Int'];
  readOnly: Scalars['Boolean'];
  viewType: ChecklistViewType;
};

export type EntitiesQueryGetChecklistsByNonClinicalEpisodeArgs = {
  episodeId: Scalars['Int'];
  readOnly: Scalars['Boolean'];
  viewType: ChecklistViewType;
};

export type EntitiesQueryGetClaimsArgs = {
  patientId?: InputMaybe<Scalars['Int']>;
};

export type EntitiesQueryGetCmUserCaseLoadAndReminderCountArgs = {
  careSiteId: Scalars['Int'];
};

export type EntitiesQueryGetDummyProviderArgs = {
  authRole?: InputMaybe<Scalars['String']>;
  patientId: Scalars['Int'];
};

export type EntitiesQueryGetDuplicateEpisodesArgs = {
  request?: InputMaybe<PatientEpisodesFilterModelInput>;
};

export type EntitiesQueryGetEpisodeArgs = {
  episodeId: Scalars['Int'];
};

export type EntitiesQueryGetEpisodeAttachmentsArgs = {
  episodeId?: InputMaybe<Scalars['Int']>;
};

export type EntitiesQueryGetEpisodeClaimsArgs = {
  episodeId: Scalars['Int'];
};

export type EntitiesQueryGetEpisodeCounterArgs = {
  episodeId: Scalars['Int'];
};

export type EntitiesQueryGetEpisodeDocumentsArgs = {
  episodeId?: InputMaybe<Scalars['Int']>;
  viewType?: InputMaybe<ChecklistViewType>;
};

export type EntitiesQueryGetEpisodeRemindersArgs = {
  episodeId?: InputMaybe<Scalars['Int']>;
};

export type EntitiesQueryGetEpisodeSummaryArgs = {
  episodeId: Scalars['Int'];
};

export type EntitiesQueryGetEpisodeSummaryHistoryArgs = {
  episodeId: Scalars['Int'];
};

export type EntitiesQueryGetEpisodeTabDataArgs = {
  request?: InputMaybe<PatientEpisodesFilterModelInput>;
};

export type EntitiesQueryGetEpisodeTabModelArgs = {
  tabType: Scalars['String'];
};

export type EntitiesQueryGetEpisodeTatArgs = {
  episodeId: Scalars['Int'];
};

export type EntitiesQueryGetEpisodeWorkflowMdStepCountArgs = {
  careSiteId?: InputMaybe<Scalars['Int']>;
  defaultAuthorization?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export type EntitiesQueryGetExtractedTextFromAttachmentArgs = {
  attachmentId: Scalars['Int'];
  pageNo: Scalars['Int'];
};

export type EntitiesQueryGetFaxDialogModelArgs = {
  episodeId?: InputMaybe<Scalars['Int']>;
  letterId?: InputMaybe<Scalars['Int']>;
  letterType?: InputMaybe<LetterType>;
};

export type EntitiesQueryGetFormattedNoteAutocompleteTextArgs = {
  episodeId: Scalars['Int'];
  searchText: Scalars['String'];
};

export type EntitiesQueryGetLetterDialogModelArgs = {
  patientId: Scalars['Int'];
};

export type EntitiesQueryGetLetterSummaryArgs = {
  checklistId?: InputMaybe<Scalars['Int']>;
};

export type EntitiesQueryGetLinkEpisodesArgs = {
  episodeId?: InputMaybe<Scalars['Int']>;
};

export type EntitiesQueryGetLookupValuesArgs = {
  includeSystemValues?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type EntitiesQueryGetLookupsArgs = {
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type EntitiesQueryGetMedicationReviewHistoryViewModelArgs = {
  patientId: Scalars['Int'];
};

export type EntitiesQueryGetMedsTabViewModelArgs = {
  patientId: Scalars['Int'];
};

export type EntitiesQueryGetMozartChecklistArgs = {
  id: Scalars['UUID'];
  readOnly?: InputMaybe<Scalars['Boolean']>;
};

export type EntitiesQueryGetMozartEntityDiffS3Args = {
  id: Scalars['UUID'];
};

export type EntitiesQueryGetMozartOutOfTurnStepsArgs = {
  id: Scalars['UUID'];
};

export type EntitiesQueryGetMozartWorkFlowActionDataArgs = {
  id: Scalars['UUID'];
};

export type EntitiesQueryGetMozartWorkflowActionsArgs = {
  id: Scalars['UUID'];
};

export type EntitiesQueryGetMozartWorkflowEpisodeAuthorizationStatusArgs = {
  id: Scalars['UUID'];
};

export type EntitiesQueryGetMultiEditDataArgs = {
  episodeIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  patientIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type EntitiesQueryGetMultiPageAttachmentArgs = {
  id: Scalars['Int'];
};

export type EntitiesQueryGetOpenedClinicalEpisodeArgs = {
  patientId?: InputMaybe<Scalars['Int']>;
};

export type EntitiesQueryGetOpenedEpisodeCarePlanProblemsArgs = {
  patientId: Scalars['Int'];
};

export type EntitiesQueryGetPatientAttachmentsArgs = {
  patientId?: InputMaybe<Scalars['Int']>;
};

export type EntitiesQueryGetPatientByIdArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type EntitiesQueryGetPatientCareTeamTabArgs = {
  patientId?: InputMaybe<Scalars['Int']>;
};

export type EntitiesQueryGetPatientContactsArgs = {
  patientId?: InputMaybe<Scalars['Int']>;
};

export type EntitiesQueryGetPatientFromAttachmentArgs = {
  attachmentId: Scalars['Int'];
};

export type EntitiesQueryGetPatientInsuranceArgs = {
  patientId: Scalars['Int'];
};

export type EntitiesQueryGetPatientInsuranceStatusArgs = {
  request?: InputMaybe<PatientInsuranceStatusActionContextInput>;
};

export type EntitiesQueryGetPatientInsuranceTabArgs = {
  patientId?: InputMaybe<Scalars['Int']>;
};

export type EntitiesQueryGetPatientOpIpAuthorizationsArgs = {
  patientId?: InputMaybe<Scalars['Int']>;
};

export type EntitiesQueryGetPatientQuickNotesArgs = {
  patientId: Scalars['Int'];
};

export type EntitiesQueryGetPatientRemindersArgs = {
  patientId: Scalars['Int'];
};

export type EntitiesQueryGetPatientRiskArgs = {
  patientId: Scalars['Int'];
};

export type EntitiesQueryGetPatientRiskClinicClassificationsArgs = {
  patientId: Scalars['Int'];
};

export type EntitiesQueryGetPatientRiskTabArgs = {
  patientId: Scalars['Int'];
};

export type EntitiesQueryGetPatientTagsArgs = {
  patientId: Scalars['Int'];
};

export type EntitiesQueryGetPatientTagsTabArgs = {
  patientId: Scalars['Int'];
};

export type EntitiesQueryGetPatientTimelineDataArgs = {
  request?: InputMaybe<TimelineRequestInput>;
};

export type EntitiesQueryGetPharmaciesArgs = {
  term: Scalars['String'];
};

export type EntitiesQueryGetPharmacyByIdArgs = {
  id: Scalars['Int'];
};

export type EntitiesQueryGetPharmacySuggestionsArgs = {
  term: Scalars['String'];
};

export type EntitiesQueryGetProblemsListArgs = {
  excludeIds: Array<InputMaybe<Scalars['Int']>>;
};

export type EntitiesQueryGetProviderAttributesArgs = {
  patientId: Scalars['Int'];
  providerAddressId?: InputMaybe<Scalars['Int']>;
  providerId: Scalars['Int'];
};

export type EntitiesQueryGetProviderDetailsArgs = {
  addressId?: InputMaybe<Scalars['Int']>;
  patientId: Scalars['Int'];
  providerId: Scalars['Int'];
};

export type EntitiesQueryGetProviderSearchDialogModelArgs = {
  authRole?: InputMaybe<Scalars['String']>;
  patientId: Scalars['Int'];
};

export type EntitiesQueryGetReminderHistoryArgs = {
  reminderId?: InputMaybe<Scalars['Int']>;
};

export type EntitiesQueryGetRemindersArgs = {
  patientId: Scalars['Int'];
};

export type EntitiesQueryGetRolesArgs = {
  ids: Array<Scalars['Int']>;
};

export type EntitiesQueryGetSystemValueByNameArgs = {
  name?: InputMaybe<Scalars['String']>;
};

export type EntitiesQueryGetSystemValuesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type EntitiesQueryGetTabModelArgs = {
  tabId?: InputMaybe<Scalars['Int']>;
};

export type EntitiesQueryGetTopReminderArgs = {
  patientId: Scalars['Int'];
};

export type EntitiesQueryGetUmRnDashboardCountArgs = {
  careSiteId: Scalars['Int'];
};

export type EntitiesQueryLoadQuickNoteArgs = {
  request: LoadQuickNoteDialogDtoInput;
};

export type EntitiesQuerySearchClaimsArgs = {
  request?: InputMaybe<SearchClaimRequestInput>;
};

export type EntitiesQuerySearchEpisodesArgs = {
  request?: InputMaybe<SearchEpisodeRequestInput>;
};

export type EntitiesQuerySearchMedicationCodesArgs = {
  request?: InputMaybe<SearchMedicationCodeActionContextInput>;
};

export type EntitiesQuerySearchPatientsArgs = {
  fullSearch: Scalars['String'];
};

export type EntitiesQuerySearchProviderArgs = {
  request?: InputMaybe<ProviderSearchRequestInput>;
};

export type EntitiesQuerySuggestEntitiesArgs = {
  entity: Scalars['String'];
  term: Scalars['String'];
};

export type EntitiesQuerySuggestProvidersArgs = {
  authRole?: InputMaybe<Scalars['String']>;
  term: Scalars['String'];
};

export type Entity = {
  __typename?: 'Entity';
  name?: Maybe<Scalars['String']>;
  values?: Maybe<Array<ILookupValue>>;
};

export type EntityAttribute = IAuditable & {
  __typename?: 'EntityAttribute';
  createdBy: Scalars['Int'];
  createdByUser?: Maybe<User>;
  createdOn?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  isDeleted: Scalars['Boolean'];
  isMultiple: Scalars['Boolean'];
  lookup?: Maybe<LookupValue>;
  name?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedByUser?: Maybe<User>;
  updatedOn?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
};

export type EntityAttributeComponent = {
  __typename?: 'EntityAttributeComponent';
  attributeType?: Maybe<Scalars['String']>;
  autocomplete: Scalars['Boolean'];
  color?: Maybe<Scalars['String']>;
  componentType: Scalars['String'];
  daysAfterLimit?: Maybe<Scalars['Int']>;
  daysBeforeLimit?: Maybe<Scalars['Int']>;
  displayOnlyWhenSelected: Scalars['Boolean'];
  entityName?: Maybe<Scalars['String']>;
  fieldName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isBigTable: Scalars['Boolean'];
  linkMessage?: Maybe<Scalars['String']>;
  maxWidth: Scalars['Int'];
  mirrorGroup?: Maybe<Scalars['String']>;
  multiValueType?: Maybe<Scalars['String']>;
  multiline: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  oneWaySrc?: Maybe<Scalars['String']>;
  parentLookupComponentId?: Maybe<Scalars['String']>;
  parentLookupSelectedValue?: Maybe<Scalars['String']>;
  primaryComparison?: Maybe<AttributeComparison>;
  readonly: Scalars['Boolean'];
  required: Scalars['Boolean'];
  secondaryComparison?: Maybe<AttributeComparison>;
  selectDateTime: Scalars['Boolean'];
  showDateTime: Scalars['Boolean'];
  style?: Maybe<Scalars['String']>;
  uniqueID: Scalars['String'];
  useCurrentDate: Scalars['Boolean'];
  useSeconds: Scalars['Boolean'];
  userDefinedId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  width: Scalars['Int'];
};

export type EntityAttributeDef = IAuditable & {
  __typename?: 'EntityAttributeDef';
  attributeType?: Maybe<AttributeType>;
  buildIn?: Maybe<Scalars['Boolean']>;
  createdBy: Scalars['Int'];
  createdByUser?: Maybe<User>;
  createdOn?: Maybe<Scalars['DateTime']>;
  definition?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSystem?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedByUser?: Maybe<User>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type EntityListColumnDefinition = {
  __typename?: 'EntityListColumnDefinition';
  alwaysDisplay?: Maybe<Scalars['Boolean']>;
  canChangeColumnPlace?: Maybe<Scalars['Boolean']>;
  canChangeSortOnlyNotEmpty?: Maybe<Scalars['Boolean']>;
  canChangeWidth?: Maybe<Scalars['Boolean']>;
  canFilter?: Maybe<Scalars['Boolean']>;
  canSort?: Maybe<Scalars['Boolean']>;
  canTruncate?: Maybe<Scalars['Boolean']>;
  clockColumn?: Maybe<Scalars['Boolean']>;
  displayName?: Maybe<Scalars['String']>;
  entityAttributeDef?: Maybe<EntityAttributeDef>;
  entityName?: Maybe<Scalars['String']>;
  filter?: Maybe<EntityListColumnFilter>;
  fullPropertyName?: Maybe<Scalars['String']>;
  hasFilter?: Maybe<Scalars['Boolean']>;
  index?: Maybe<Scalars['Int']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  propertyName?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<EnumSortDirection>;
  sortOnlyNotEmpty?: Maybe<Scalars['Boolean']>;
  sortOrder?: Maybe<Scalars['Int']>;
  truncate?: Maybe<Scalars['Boolean']>;
  width?: Maybe<Scalars['Int']>;
};

export type EntityListColumnFilter = {
  __typename?: 'EntityListColumnFilter';
  entityName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  lookupName?: Maybe<Scalars['String']>;
  multiValues?: Maybe<Scalars['Boolean']>;
  propertyName?: Maybe<Scalars['String']>;
  templateName?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

export type EntityListTabDefinition = {
  __typename?: 'EntityListTabDefinition';
  index?: Maybe<Scalars['Int']>;
  isBuiltIn?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  notificationIconValue?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  useElastic?: Maybe<Scalars['Boolean']>;
};

export enum EnumRoleType {
  Administrator = 'ADMINISTRATOR',
  Clinical = 'CLINICAL',
  NonClinical = 'NON_CLINICAL',
}

export enum EnumSortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
  None = 'NONE',
}

export type Episode = IAuditable & {
  __typename?: 'Episode';
  actualDischargeDate?: Maybe<Scalars['Date']>;
  admittingClinician?: Maybe<Provider>;
  appealGrievClaimNumber?: Maybe<Scalars['String']>;
  appealGrievMemberAction?: Maybe<LookupValue>;
  appealGrievMemberAppealType?: Maybe<LookupValue>;
  appealGrievMemberDenialReason?: Maybe<LookupValue>;
  appealGrievProviderAction?: Maybe<LookupValue>;
  appealGrievProviderAppealType?: Maybe<LookupValue>;
  appealGrievProviderDenialReason?: Maybe<LookupValue>;
  appealGrievResolution?: Maybe<LookupValue>;
  appealGrievStatus?: Maybe<LookupValue>;
  approvedUnitType?: Maybe<LookupValue>;
  approvedUnits?: Maybe<Scalars['String']>;
  authorizationCategory?: Maybe<LookupValue>;
  authorizationDenialLetter?: Maybe<Scalars['Date']>;
  authorizationExpDate?: Maybe<Scalars['Date']>;
  authorizationRequestedService?: Maybe<LookupValue>;
  authorizationStartDate?: Maybe<Scalars['Date']>;
  authorizationStatus?: Maybe<LookupValue>;
  authorizationTimeliness?: Maybe<LookupValue>;
  authorizationType?: Maybe<LookupValue>;
  authorizationVerbalNotification?: Maybe<Scalars['Date']>;
  careSite?: Maybe<CareSite>;
  careStage?: Maybe<LookupValue>;
  clinical?: Maybe<Scalars['Boolean']>;
  clinicalService?: Maybe<LookupValue>;
  clocks?: Maybe<Array<Maybe<Clock>>>;
  closedOn?: Maybe<Scalars['DateTime']>;
  complaintCode1?: Maybe<LookupValue>;
  complaintCode2?: Maybe<LookupValue>;
  complaintCode3?: Maybe<LookupValue>;
  createdBy: Scalars['Int'];
  createdByUser?: Maybe<User>;
  createdOn?: Maybe<Scalars['DateTime']>;
  determinationDate?: Maybe<Scalars['Date']>;
  diagnosisCodesAll?: Maybe<Array<Maybe<MedicationCode>>>;
  dueDate?: Maybe<Scalars['Date']>;
  entityAttributes?: Maybe<Array<Maybe<EntityAttribute>>>;
  episodeNumber?: Maybe<Scalars['String']>;
  facility?: Maybe<Provider>;
  faxDateTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  internalStartDate?: Maybe<Scalars['Date']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  modifier?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  open?: Maybe<Scalars['Boolean']>;
  owner?: Maybe<User>;
  priority?: Maybe<LookupValue>;
  procedureCodesAll?: Maybe<Array<Maybe<MedicationCode>>>;
  program?: Maybe<LookupValue>;
  receiptDate?: Maybe<Scalars['DateTime']>;
  referenceNumber?: Maybe<Scalars['String']>;
  referralDate?: Maybe<Scalars['Date']>;
  reminders?: Maybe<Array<Maybe<BaseReminder>>>;
  requestedDos?: Maybe<Scalars['Date']>;
  requestedEndDate?: Maybe<Scalars['Date']>;
  requestedLos?: Maybe<Scalars['Int']>;
  requestedUnitType?: Maybe<LookupValue>;
  requestedUnits?: Maybe<Scalars['String']>;
  requestingClinician?: Maybe<Provider>;
  scheduledStatus?: Maybe<LookupValue>;
  servicingClinician?: Maybe<Provider>;
  source?: Maybe<LookupValue>;
  state?: Maybe<EpisodeState>;
  status?: Maybe<LookupValue>;
  type?: Maybe<EpisodeType>;
  unitAmount?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedByUser?: Maybe<User>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type EpisodeAutoAssign = {
  __typename?: 'EpisodeAutoAssign';
  attachmentId?: Maybe<Scalars['Int']>;
  careSiteId?: Maybe<Scalars['Int']>;
  careSiteName?: Maybe<Scalars['String']>;
  careStageName?: Maybe<Scalars['String']>;
  checklistId?: Maybe<Scalars['Int']>;
  episodeId?: Maybe<Scalars['Int']>;
  episodeNumber?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['Int']>;
  status?: Maybe<AutoAssignStatus>;
  workflowInstanceId?: Maybe<Scalars['String']>;
};

export type EpisodeBalancerCriteria = {
  __typename?: 'EpisodeBalancerCriteria';
  careSites: Array<ILookupValue>;
  careStages: Array<ILookupValue>;
  pathways: Array<ILookupValue>;
  serviceLocations: Array<ILookupValue>;
  totalEpisodesOwned: Scalars['Int'];
  users: Array<ILookupValue>;
};

export type EpisodeBalancerCriteriaInput = {
  careSiteIds: Array<Scalars['Int']>;
  careStageIds: Array<Scalars['Int']>;
  pathwayIds: Array<Scalars['Int']>;
  serviceLocationIds: Array<Scalars['Int']>;
  totalEpisodesOwned: Scalars['Int'];
  userIds: Array<Scalars['Int']>;
};

export type EpisodeBaseViewModel =
  | AppealGrievViewModel
  | CaseEpisodeViewModel
  | EpisodeViewModel
  | EpisodeWithMozartInfoViewModel;

export type EpisodeSearchEpisodeResult = {
  __typename?: 'EpisodeSearchEpisodeResult';
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  rows: Array<Episode>;
  totalCount: Scalars['Int'];
};

export enum EpisodeState {
  Closed = 'CLOSED',
  Opened = 'OPENED',
}

export type EpisodeSummary = {
  __typename?: 'EpisodeSummary';
  author?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  summary?: Maybe<Scalars['String']>;
  timeStamp?: Maybe<Scalars['DateTime']>;
};

export type EpisodeTabTypes = {
  __typename?: 'EpisodeTabTypes';
  episodeTypes?: Maybe<Array<Maybe<Scalars['Int']>>>;
  tabName?: Maybe<Scalars['String']>;
};

export type EpisodeType = IAuditable &
  ILookupValue & {
    __typename?: 'EpisodeType';
    createdBy: Scalars['Int'];
    createdByUser?: Maybe<User>;
    createdOn?: Maybe<Scalars['DateTime']>;
    id: Scalars['Int'];
    name?: Maybe<Scalars['String']>;
    updatedBy?: Maybe<Scalars['Int']>;
    updatedByUser?: Maybe<User>;
    updatedOn?: Maybe<Scalars['DateTime']>;
  };

export type EpisodeViewModel = {
  __typename?: 'EpisodeViewModel';
  allowViewEpisodeDetail?: Maybe<Scalars['Boolean']>;
  allowedUpdate?: Maybe<Scalars['Boolean']>;
  anyProgress?: Maybe<Scalars['Boolean']>;
  appealGrievProviderAppealType?: Maybe<Scalars['String']>;
  appealStartDate?: Maybe<Scalars['Date']>;
  authStatus?: Maybe<LookupValue>;
  authorizationCategory?: Maybe<Scalars['String']>;
  authorizationStartDate?: Maybe<Scalars['Date']>;
  authorizationVendor?: Maybe<Scalars['String']>;
  careStage?: Maybe<Scalars['String']>;
  counter?: Maybe<CounterClockColumnViewModel>;
  createdBy?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  episodeNumber?: Maybe<Scalars['String']>;
  episodePathway?: Maybe<Scalars['String']>;
  episodeServiceLocation?: Maybe<Scalars['String']>;
  facility?: Maybe<Scalars['String']>;
  faxDateTime?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  incidentRegulatory?: Maybe<Scalars['Boolean']>;
  isAppealed?: Maybe<Scalars['Boolean']>;
  medicarePartType?: Maybe<Scalars['String']>;
  patientId: Scalars['Int'];
  procedureCode?: Maybe<Scalars['String']>;
  requestedBy?: Maybe<Scalars['String']>;
  servicingProvider?: Maybe<Scalars['String']>;
  state?: Maybe<EpisodeState>;
  status?: Maybe<LookupValue>;
  tat?: Maybe<TatClockColumnViewModel>;
  timeZone?: Maybe<Scalars['String']>;
  topReminder?: Maybe<BaseReminder>;
  type?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type EpisodeWithMozartInfoViewModel = {
  __typename?: 'EpisodeWithMozartInfoViewModel';
  allowViewEpisodeDetail?: Maybe<Scalars['Boolean']>;
  allowedUpdate?: Maybe<Scalars['Boolean']>;
  anyProgress?: Maybe<Scalars['Boolean']>;
  appealGrievProviderAppealType?: Maybe<Scalars['String']>;
  appealStartDate?: Maybe<Scalars['Date']>;
  authStatus?: Maybe<LookupValue>;
  authorizationCategory?: Maybe<Scalars['String']>;
  authorizationStartDate?: Maybe<Scalars['Date']>;
  authorizationVendor?: Maybe<Scalars['String']>;
  careStage?: Maybe<Scalars['String']>;
  counter?: Maybe<CounterClockColumnViewModel>;
  createdBy?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  episodeNumber?: Maybe<Scalars['String']>;
  episodePathway?: Maybe<Scalars['String']>;
  episodeServiceLocation?: Maybe<Scalars['String']>;
  facility?: Maybe<Scalars['String']>;
  faxDateTime?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  incidentRegulatory?: Maybe<Scalars['Boolean']>;
  isAppealed?: Maybe<Scalars['Boolean']>;
  medicarePartType?: Maybe<Scalars['String']>;
  mozartActionEnabled: Scalars['Boolean'];
  mozartWorkflowCompleted: Scalars['Boolean'];
  nextStep?: Maybe<Scalars['String']>;
  patientId: Scalars['Int'];
  procedureCode?: Maybe<Scalars['String']>;
  requestedBy?: Maybe<Scalars['String']>;
  servicingProvider?: Maybe<Scalars['String']>;
  state?: Maybe<EpisodeState>;
  status?: Maybe<LookupValue>;
  tat?: Maybe<TatClockColumnViewModel>;
  timeZone?: Maybe<Scalars['String']>;
  topReminder?: Maybe<BaseReminder>;
  type?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  workFlowInstanceId: Scalars['ID'];
};

export type EpisodeWorkflowMdStepCountViewModel = {
  __typename?: 'EpisodeWorkflowMdStepCountViewModel';
  cSRMDCount: Scalars['Int'];
  careSiteId: Scalars['Int'];
  careSiteName?: Maybe<Scalars['String']>;
  mDAppealReviewCount: Scalars['Int'];
  mDP2PReviewCount: Scalars['Int'];
  mDReviewCount: Scalars['Int'];
};

export type EpisodesTabChecklist = {
  __typename?: 'EpisodesTabChecklist';
  checklist?: Maybe<Checklist>;
  checklistOrderSets?: Maybe<Array<Maybe<ChecklistOrderSetRowView>>>;
  containsAttachments?: Maybe<Scalars['Boolean']>;
  operations?: Maybe<Array<Maybe<ChecklistOperation>>>;
};

export type ExpressionAttributeType = {
  __typename?: 'ExpressionAttributeType';
  dataType?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  elasticExpression?: Maybe<Scalars['Int']>;
  entityName: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['ID'];
  jsExpression?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sqlExpression?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type ExpressionComponent = {
  __typename?: 'ExpressionComponent';
  attributeForUpdate?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  componentType: Scalars['String'];
  displayOnlyWhenSelected: Scalars['Boolean'];
  expressionString?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isDrawableOnFinish: Scalars['Boolean'];
  isReadonlyAfterFinishSet?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  readonlyAfterFinish?: Maybe<Scalars['Boolean']>;
  required: Scalars['Boolean'];
  style?: Maybe<Scalars['String']>;
  uniqueID: Scalars['String'];
  userDefinedId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  width: Scalars['Int'];
};

export type ExternalUrlComponent = {
  __typename?: 'ExternalUrlComponent';
  color?: Maybe<Scalars['String']>;
  componentType: Scalars['String'];
  displayOnlyWhenSelected: Scalars['Boolean'];
  id: Scalars['String'];
  isDrawableOnFinish: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  required: Scalars['Boolean'];
  style?: Maybe<Scalars['String']>;
  uniqueID: Scalars['String'];
  userDefinedId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  width: Scalars['Int'];
};

export type FamilyMemberDtoInput = {
  consentToShare: Scalars['Boolean'];
  id: Scalars['Int'];
  isCaregiver: Scalars['Boolean'];
  name?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  relationshipId: Scalars['Int'];
};

export type FaxDialogViewModel = {
  __typename?: 'FaxDialogViewModel';
  faxNumbers?: Maybe<Array<CareSiteFaxNumberViewModel>>;
  note?: Maybe<Scalars['String']>;
  recipientFaxNumber?: Maybe<Scalars['String']>;
  recipientTo?: Maybe<Scalars['String']>;
  senderFrom?: Maybe<Scalars['String']>;
  senderRespondTo?: Maybe<Scalars['Int']>;
};

export type FaxParameters = {
  __typename?: 'FaxParameters';
  isFaxConfiguredByCareSite?: Maybe<Scalars['Boolean']>;
  isFaxConfiguredByRole?: Maybe<Scalars['Boolean']>;
  isFaxCoverCreated?: Maybe<Scalars['Boolean']>;
  isLetterTransmittalByFax?: Maybe<Scalars['Boolean']>;
};

export type FinishChecklistModelInput = {
  checklistId: Scalars['Int'];
  completeReminder?: InputMaybe<Scalars['Boolean']>;
  patientId: Scalars['Int'];
  sessionId: Scalars['String'];
};

export type FinishChecklistResultModel = {
  __typename?: 'FinishChecklistResultModel';
  checklistId: Scalars['Int'];
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  reminderCount?: Maybe<Scalars['Int']>;
  status: FinishChecklistStatus;
};

export enum FinishChecklistStatus {
  Error = 'ERROR',
  Locked = 'LOCKED',
  OpenReminders = 'OPEN_REMINDERS',
  Success = 'SUCCESS',
}

export type FixedTextComponent = {
  __typename?: 'FixedTextComponent';
  color?: Maybe<Scalars['String']>;
  componentType: Scalars['String'];
  displayOnlyWhenSelected: Scalars['Boolean'];
  hidden: Scalars['Boolean'];
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['String']>;
  uniqueID: Scalars['String'];
  userDefinedId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  width: Scalars['Int'];
};

export type FloatAttributeType = {
  __typename?: 'FloatAttributeType';
  dataType?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  entityName: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  numberDecimalDigits: Scalars['Int'];
  type: Scalars['String'];
};

export enum FoundProviderStatus {
  AllMatched = 'ALL_MATCHED',
  NotMatched = 'NOT_MATCHED',
  NotSet = 'NOT_SET',
  NoActiveLob = 'NO_ACTIVE_LOB',
  PartlyMatched = 'PARTLY_MATCHED',
}

export type GetMozartActionProcessResult = {
  __typename?: 'GetMozartActionProcessResult';
  checklist?: Maybe<Checklist>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  mozartWorkflowInstanceState?: Maybe<Scalars['String']>;
  nextActionId?: Maybe<Scalars['UUID']>;
  nextStepId?: Maybe<Scalars['UUID']>;
  pollingTimeout: Scalars['Int'];
  steps?: Maybe<Array<Maybe<MozartWorkflowUiModel>>>;
};

export type GetMozartWorkflowActionsResult = {
  __typename?: 'GetMozartWorkflowActionsResult';
  actions: Array<MozartWorkflowActionInfo>;
};

/** A connection to a list of items. */
export type GetSystemValuesConnection = {
  __typename?: 'GetSystemValuesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<GetSystemValuesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<SystemValue>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type GetSystemValuesEdge = {
  __typename?: 'GetSystemValuesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<SystemValue>;
};

export type GetUserProvidersSelectModel = {
  __typename?: 'GetUserProvidersSelectModel';
  careSites: Array<GetUserProvidersSelectModelCareSite>;
  providers: Array<GetUserProvidersSelectModelProvider>;
  selectedProviderId?: Maybe<Scalars['Int']>;
  showDialog: Scalars['Boolean'];
  showProvidersSelect: Scalars['Boolean'];
};

export type GetUserProvidersSelectModelCareSite = {
  __typename?: 'GetUserProvidersSelectModelCareSite';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type GetUserProvidersSelectModelProvider = {
  __typename?: 'GetUserProvidersSelectModelProvider';
  careSiteId: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['String'];
  npi?: Maybe<Scalars['String']>;
  providerId?: Maybe<Scalars['String']>;
};

export type GetWorkflowEpisodeAuthorizationStatusResult = {
  __typename?: 'GetWorkflowEpisodeAuthorizationStatusResult';
  authorizationStatus?: Maybe<LookupValue>;
  style?: Maybe<Scalars['String']>;
};

export type HealthServiceActionValue = {
  __typename?: 'HealthServiceActionValue';
  approvedEndDate?: Maybe<Scalars['Date']>;
  approvedStartDate?: Maybe<Scalars['Date']>;
  approvedUnitType?: Maybe<Scalars['String']>;
  approvedUnits?: Maybe<Scalars['Int']>;
  determinationDecision?: Maybe<Scalars['String']>;
  determinationReason?: Maybe<LookupValue>;
  modifier?: Maybe<LookupValue>;
  requestedEndDate?: Maybe<Scalars['Date']>;
  requestedStartDate?: Maybe<Scalars['Date']>;
  requestedUnitType?: Maybe<Scalars['String']>;
  requestedUnits?: Maybe<Scalars['Int']>;
};

export type HealthServiceCodesActionModel = {
  __typename?: 'HealthServiceCodesActionModel';
  healthServiceCodesActionValues: Array<Maybe<HealthServiceCodesActionValue>>;
  isApprOverriden?: Maybe<Scalars['Boolean']>;
  isReqOverriden?: Maybe<Scalars['Boolean']>;
  overallApprEndDate?: Maybe<Scalars['Date']>;
  overallApprStartDate?: Maybe<Scalars['Date']>;
  overallApprUnitType?: Maybe<LookupValue>;
  overallDeterminationDecision?: Maybe<Scalars['String']>;
  overallDeterminationReason?: Maybe<LookupValue>;
  overallReqEndDate?: Maybe<Scalars['Date']>;
  overallReqStartDate?: Maybe<Scalars['Date']>;
  overallReqUnitType?: Maybe<LookupValue>;
  totalApprUnits?: Maybe<Scalars['Int']>;
  totalDeniedUnits?: Maybe<Scalars['Int']>;
  totalReqUnits?: Maybe<Scalars['Int']>;
};

export type HealthServiceCodesActionValue = {
  __typename?: 'HealthServiceCodesActionValue';
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  healthServiceActionValue?: Maybe<HealthServiceActionValue>;
  id: Scalars['Int'];
  notificationMessage?: Maybe<Scalars['String']>;
};

export type HealthServiceCodesOrderableOptions = {
  __typename?: 'HealthServiceCodesOrderableOptions';
  category?: Maybe<LookupValue>;
  codeLabel: Scalars['String'];
  descriptionLabel: Scalars['String'];
  hSAllowAddingCodes?: Maybe<Scalars['Boolean']>;
  hSAllowApproved: Scalars['Boolean'];
  hSAllowDeletingCode?: Maybe<Scalars['Boolean']>;
  hSAllowDenied: Scalars['Boolean'];
  hSAllowPartial: Scalars['Boolean'];
  hSAllowPending: Scalars['Boolean'];
  hSApprovedReasonLookup?: Maybe<Scalars['String']>;
  hSAuthorizationType: Scalars['Int'];
  hSDefaultUnit?: Maybe<Scalars['String']>;
  hSDeniedReasonLookup?: Maybe<Scalars['String']>;
  hSDisplayEpisodeLevelRequestedParam?: Maybe<Scalars['Boolean']>;
  hSDisplayMode: Scalars['Int'];
  hSOverallApprovedEndDate?: Maybe<Scalars['String']>;
  hSOverallApprovedStartDate?: Maybe<Scalars['String']>;
  hSOverallDeterminationDecision?: Maybe<Scalars['String']>;
  hSOverallDeterminationReason?: Maybe<Scalars['String']>;
  hSOverallRequestedEndDate?: Maybe<Scalars['String']>;
  hSOverallRequestedStartDate?: Maybe<Scalars['String']>;
  hSPartialReasonLookup?: Maybe<Scalars['String']>;
  hSPendingReasonLookup?: Maybe<Scalars['String']>;
  hSTotalApprovedUnit: Scalars['String'];
  hSTotalApprovedUnitType: Scalars['String'];
  hSTotalRequestedUnit: Scalars['String'];
  hSTotalRequestedUnitType: Scalars['String'];
  hSUnitTypeEditable: Scalars['Boolean'];
  itemType: MedicationCodeItemType;
  maxAllowed: Scalars['Int'];
  minRequired: Scalars['Int'];
  type: MedicationCodeType;
};

export type HomePageModel = {
  __typename?: 'HomePageModel';
  activeTab?: Maybe<Scalars['Int']>;
  filter?: Maybe<Array<Maybe<KeyValuePairOfStringAndString>>>;
  settings?: Maybe<HomePageSettings>;
  tabs?: Maybe<Array<Maybe<EntityListTabDefinition>>>;
};

export type HomePageSettings = {
  __typename?: 'HomePageSettings';
  episodesLabel?: Maybe<Scalars['String']>;
  pageSize: Scalars['Int'];
  showActive?: Maybe<Scalars['Boolean']>;
};

export type HomeTabModel = {
  __typename?: 'HomeTabModel';
  columns?: Maybe<Array<Maybe<EntityListColumnDefinition>>>;
  episodeState?: Maybe<Scalars['Int']>;
  filterValues?: Maybe<Array<Maybe<Lookup>>>;
  showProgress?: Maybe<Scalars['Boolean']>;
};

export type IAuditable = {
  createdBy: Scalars['Int'];
  createdOn?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type ILookupValue = {
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

export type IntAttributeType = {
  __typename?: 'IntAttributeType';
  dataType?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  entityName: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['ID'];
  maxLength?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  regexErrorText?: Maybe<Scalars['String']>;
  regexMask?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type KeyValuePairOfInt32AndInt32Input = {
  key?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['Int']>;
};

export type KeyValuePairOfStringAndObject = {
  __typename?: 'KeyValuePairOfStringAndObject';
  key: Scalars['String'];
};

export type KeyValuePairOfStringAndString = {
  __typename?: 'KeyValuePairOfStringAndString';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type KeyValuePairOfStringAndStringInput = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type LanguageBarrierDto = {
  __typename?: 'LanguageBarrierDto';
  isEnabled?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
};

export type LetterDialogModel = {
  __typename?: 'LetterDialogModel';
  faxParameter?: Maybe<FaxParameters>;
  letterGroups?: Maybe<Array<Maybe<DictionaryValue>>>;
  letters?: Maybe<Array<Maybe<LetterModel>>>;
  patientLanguage?: Maybe<LookupValue>;
  printShopIsActive?: Maybe<Scalars['Boolean']>;
};

export type LetterDtoInput = {
  attachmentPathTemp?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  type: LetterType;
};

export type LetterModel = {
  __typename?: 'LetterModel';
  id: Scalars['Int'];
  letterGroup?: Maybe<Scalars['String']>;
  letterType: LetterType;
  name: Scalars['String'];
};

export type LetterPreviewModelInput = {
  checklistId: Scalars['Int'];
  episodeId: Scalars['Int'];
  letters: Array<LetterDtoInput>;
  patientId: Scalars['Int'];
};

export type LetterSummaryActionValue = {
  __typename?: 'LetterSummaryActionValue';
  createdByUser?: Maybe<LetterSummaryActionValueCreatedByUser>;
  createdOn?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  fileExtension?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type LetterSummaryActionValueCreatedByUser = {
  __typename?: 'LetterSummaryActionValueCreatedByUser';
  fullName?: Maybe<Scalars['String']>;
};

export type LetterSummaryOrderableOptions = {
  __typename?: 'LetterSummaryOrderableOptions';
  codeLabel: Scalars['String'];
};

export enum LetterType {
  DocumentTemplate = 'DOCUMENT_TEMPLATE',
  DocumentTemplateBlock = 'DOCUMENT_TEMPLATE_BLOCK',
}

export type LinkEpisode = {
  __typename?: 'LinkEpisode';
  episodeId: Scalars['Int'];
  episodeNumber: Scalars['String'];
  episodeTypeId?: Maybe<Scalars['Int']>;
  linkEpisodeId: Scalars['String'];
};

export enum ListStyleType {
  BulletPoint = 'BULLET_POINT',
  Checkbox = 'CHECKBOX',
  None = 'NONE',
}

export type LoadQuickNoteDialogDtoInput = {
  baseQuickNoteId?: InputMaybe<Scalars['Int']>;
  episodeId?: InputMaybe<Scalars['Int']>;
  patientId: Scalars['Int'];
  quickNoteType: QuickNoteType;
};

export type Lookup = IAuditable &
  ILookupValue & {
    __typename?: 'Lookup';
    createdBy: Scalars['Int'];
    createdByUser?: Maybe<User>;
    createdOn?: Maybe<Scalars['DateTime']>;
    id: Scalars['Int'];
    isDeleted?: Maybe<Scalars['Boolean']>;
    name?: Maybe<Scalars['String']>;
    sortedValues?: Maybe<Array<Maybe<LookupValue>>>;
    updatedBy?: Maybe<Scalars['Int']>;
    updatedByUser?: Maybe<User>;
    updatedOn?: Maybe<Scalars['DateTime']>;
    values?: Maybe<Array<Maybe<LookupValue>>>;
  };

export type LookupAttributeType = {
  __typename?: 'LookupAttributeType';
  dataType?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  entityName: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['ID'];
  lookup?: Maybe<Lookup>;
  multiValues: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  referenceByValue: Scalars['Boolean'];
  type: Scalars['String'];
};

export type LookupValue = IAuditable &
  ILookupValue & {
    __typename?: 'LookupValue';
    createdBy: Scalars['Int'];
    createdByUser?: Maybe<User>;
    createdOn?: Maybe<Scalars['DateTime']>;
    id: Scalars['Int'];
    image?: Maybe<Scalars['String']>;
    isDefault?: Maybe<Scalars['Boolean']>;
    isDeleted?: Maybe<Scalars['Boolean']>;
    name?: Maybe<Scalars['String']>;
    parentLookupValueId?: Maybe<Scalars['Int']>;
    sortOrder?: Maybe<Scalars['Int']>;
    style?: Maybe<Scalars['String']>;
    updatedBy?: Maybe<Scalars['Int']>;
    updatedByUser?: Maybe<User>;
    updatedOn?: Maybe<Scalars['DateTime']>;
  };

export type MedicationAttributeTypesViewModel = {
  __typename?: 'MedicationAttributeTypesViewModel';
  displayName: Scalars['String'];
  name: Scalars['String'];
  required: Scalars['Boolean'];
};

export type MedicationCode = IAuditable & {
  __typename?: 'MedicationCode';
  code?: Maybe<Scalars['String']>;
  codeCategory?: Maybe<Scalars['Int']>;
  consumerFriendlyDescription?: Maybe<Scalars['String']>;
  createdBy: Scalars['Int'];
  createdByUser?: Maybe<User>;
  createdOn?: Maybe<Scalars['DateTime']>;
  effectiveDate?: Maybe<Scalars['Date']>;
  expirationDate?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  longDescription?: Maybe<Scalars['String']>;
  notificationMessage?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  type?: Maybe<MedicationCodeType>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedByUser?: Maybe<User>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export enum MedicationCodeCategoryType {
  AllowAllCodes = 'ALLOW_ALL_CODES',
  None = 'NONE',
  NonPartB = 'NON_PART_B',
  PartBOnly = 'PART_B_ONLY',
}

export enum MedicationCodeItemType {
  HealthServices = 'HEALTH_SERVICES',
  MedicalCodes = 'MEDICAL_CODES',
  PriorAuthCodes = 'PRIOR_AUTH_CODES',
}

export enum MedicationCodeType {
  CptHcpcs = 'CPT_HCPCS',
  Icd_10Cm = 'ICD_10_CM',
}

export type MedicationCodesActionValue = {
  __typename?: 'MedicationCodesActionValue';
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  healthServiceActionValue?: Maybe<HealthServiceActionValue>;
  id: Scalars['Int'];
  isPrimary: Scalars['Boolean'];
  notificationMessage?: Maybe<Scalars['String']>;
};

export type MedicationCodesOrderableOptions = {
  __typename?: 'MedicationCodesOrderableOptions';
  allowedCodeCategory: MedicationCodeCategoryType;
  category?: Maybe<LookupValue>;
  codeLabel: Scalars['String'];
  descriptionLabel: Scalars['String'];
  disAllowedCodeMessage?: Maybe<Scalars['String']>;
  itemType: MedicationCodeItemType;
  maxAllowed: Scalars['Int'];
  minRequired: Scalars['Int'];
  type: MedicationCodeType;
};

export type MedicationReviewHistoryViewModel = {
  __typename?: 'MedicationReviewHistoryViewModel';
  reviewedByName?: Maybe<Scalars['String']>;
  reviewedByRoleName?: Maybe<Scalars['String']>;
  reviewedOn?: Maybe<Scalars['DateTime']>;
};

export type MedicationViewModel = {
  __typename?: 'MedicationViewModel';
  byVerbalReport?: Maybe<Scalars['Boolean']>;
  dateFilled?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  instructions?: Maybe<Scalars['String']>;
  mdRecommended?: Maybe<Scalars['Boolean']>;
  medication?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  patientState: PatientMedicationStatus;
  patientUse?: Maybe<PatientMedicationPatientUse>;
  pharmacy?: Maybe<Scalars['String']>;
  pharmacyFax?: Maybe<Scalars['String']>;
  pharmacyPhone?: Maybe<Scalars['String']>;
  provider?: Maybe<Provider>;
  quantityFilled?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  refills?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  type: PatientMedicationType;
};

export type MedsTabViewModel = {
  __typename?: 'MedsTabViewModel';
  claimsDataAvailable?: Maybe<Scalars['Boolean']>;
  filterState: PatientMedicationStatusFilter;
  medicationAttributeTypes: Array<MedicationAttributeTypesViewModel>;
  medications: Array<MedicationViewModel>;
};

export type MergeResult = {
  __typename?: 'MergeResult';
  filePath?: Maybe<Scalars['String']>;
  isError: Scalars['Boolean'];
};

export type MoveAttachmentsModelInput = {
  attachmentId: Scalars['Int'];
  targetEpisodeId?: InputMaybe<Scalars['Int']>;
  targetPatientId?: InputMaybe<Scalars['Int']>;
};

export type MozartActionResult = {
  __typename?: 'MozartActionResult';
  isDataValidationExceptionOccured?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  result: Scalars['Boolean'];
};

export enum MozartActionType {
  AutoApproval = 'AUTO_APPROVAL',
  AutoBalancer = 'AUTO_BALANCER',
  CheckAttachments = 'CHECK_ATTACHMENTS',
  CreateEpisode = 'CREATE_EPISODE',
  DuplicateCheck = 'DUPLICATE_CHECK',
  Escalate = 'ESCALATE',
  GenerateReminder = 'GENERATE_REMINDER',
  JumpTo = 'JUMP_TO',
  JumpToAction = 'JUMP_TO_ACTION',
  JumpToPreviousStep = 'JUMP_TO_PREVIOUS_STEP',
  JumpToStep = 'JUMP_TO_STEP',
  None = 'NONE',
  OcrCheck = 'OCR_CHECK',
  PauseOnAction = 'PAUSE_ON_ACTION',
  PauseWorkflow = 'PAUSE_WORKFLOW',
  PriorAuth = 'PRIOR_AUTH',
  SearchEpisode = 'SEARCH_EPISODE',
  SearchLetter = 'SEARCH_LETTER',
  SendEmail = 'SEND_EMAIL',
  SendLetter = 'SEND_LETTER',
  ShowMessage = 'SHOW_MESSAGE',
  ShowModule = 'SHOW_MODULE',
  UpdateModel = 'UPDATE_MODEL',
  UserInput = 'USER_INPUT',
  WorkflowException = 'WORKFLOW_EXCEPTION',
}

export type MozartDataResult = {
  __typename?: 'MozartDataResult';
  data?: Maybe<Scalars['String']>;
  result: Scalars['Boolean'];
};

export enum MozartDefinitionType {
  Action = 'ACTION',
  Exception = 'EXCEPTION',
  None = 'NONE',
  Step = 'STEP',
  Workflow = 'WORKFLOW',
}

export enum MozartProgressState {
  Completed = 'COMPLETED',
  Discarded = 'DISCARDED',
  Failed = 'FAILED',
  Initialized = 'INITIALIZED',
  Inprogress = 'INPROGRESS',
  None = 'NONE',
  Paused = 'PAUSED',
  PendingUserInput = 'PENDING_USER_INPUT',
  PermanentFailure = 'PERMANENT_FAILURE',
  Skipped = 'SKIPPED',
  Started = 'STARTED',
  UserInputComplete = 'USER_INPUT_COMPLETE',
}

export type MozartStartWorkflowResult = {
  __typename?: 'MozartStartWorkflowResult';
  checklist?: Maybe<Checklist>;
  episodeId: Scalars['Int'];
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  pollingTimeout: Scalars['Int'];
  selectedScenario?: Maybe<Scenario>;
  userInput: Scalars['Boolean'];
  viewDataTmp?: Maybe<Array<KeyValuePairOfStringAndObject>>;
  workflowInstanceId: Scalars['UUID'];
};

export type MozartWorkflowActionInfo = {
  __typename?: 'MozartWorkflowActionInfo';
  actionId: Scalars['UUID'];
  checklistId?: Maybe<Scalars['Int']>;
  stepName?: Maybe<Scalars['String']>;
};

export type MozartWorkflowUiModel = {
  __typename?: 'MozartWorkflowUIModel';
  actionInstanceId: Scalars['UUID'];
  actionType: MozartActionType;
  autoScrollToActionInstanceId?: Maybe<Scalars['UUID']>;
  checkListId: Scalars['Int'];
  createdBy?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  entityAttribute?: Maybe<Array<KeyValuePairOfStringAndString>>;
  executionNote?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  leftHead?: Maybe<Scalars['String']>;
  postEntityDiffLocationURI?: Maybe<Scalars['String']>;
  retryCount?: Maybe<Scalars['Int']>;
  rightHead?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Scalars['Int']>>;
  scopeId: Scalars['UUID'];
  showMessageKey?: Maybe<Scalars['String']>;
  showMessageValue?: Maybe<Scalars['String']>;
  state: MozartProgressState;
  stepDisplayName?: Maybe<Scalars['String']>;
  stepInstanceId: Scalars['UUID'];
  stepName?: Maybe<Scalars['String']>;
  type: MozartDefinitionType;
  uniqueMsgId: Scalars['UUID'];
  updatedBy?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
  workflowInstanceId: Scalars['UUID'];
};

export type MultiEditDataModel = {
  __typename?: 'MultiEditDataModel';
  careStage?: Maybe<ILookupValue>;
  episodeOwner?: Maybe<ILookupValue>;
  isActive?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
  patientOwner?: Maybe<ILookupValue>;
  patientSecondaryOwner?: Maybe<ILookupValue>;
  payor?: Maybe<ILookupValue>;
  populations?: Maybe<Array<Maybe<ILookupValue>>>;
  primaryCareProvider?: Maybe<ILookupValue>;
  providerName?: Maybe<Scalars['String']>;
  status?: Maybe<ILookupValue>;
};

export type MultiEditModel = {
  __typename?: 'MultiEditModel';
  canChangeOwner?: Maybe<Scalars['Boolean']>;
  data?: Maybe<MultiEditDataModel>;
  episodeOnly?: Maybe<Scalars['Boolean']>;
  requiredFields: Array<Scalars['String']>;
};

export type MultiPageAttachmentViewModel = {
  __typename?: 'MultiPageAttachmentViewModel';
  attachmentId: Scalars['Int'];
  attachmentName?: Maybe<Scalars['String']>;
  pagesCount: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addAttachments: Array<Attachment>;
  addChecklistAction?: Maybe<ChecklistViewModel>;
  addChecklistProblems?: Maybe<Scalars['Boolean']>;
  addEpisodeBalancerCriteriaToQueue?: Maybe<Scalars['Boolean']>;
  addProviderToRecentProviders?: Maybe<Scalars['Boolean']>;
  addQuickNoteAction?: Maybe<ChecklistViewModel>;
  amendChecklist?: Maybe<Scalars['Int']>;
  attachFromEpisodeToEpisode?: Maybe<ActionEpisodeResult>;
  changeReminderState?: Maybe<Scalars['Boolean']>;
  closeEpisode?: Maybe<CloseEpisodeResult>;
  continueMozartStep?: Maybe<MozartActionResult>;
  continueMozartWorkflow?: Maybe<MozartActionResult>;
  createAndViewWorkflowChecklist?: Maybe<CreateAndViewWorkflowChecklistResult>;
  createChecklist?: Maybe<CreateChecklistResultModel>;
  createOrUpdateCarePlan?: Maybe<ChecklistViewModel>;
  createOrUpdatePatient?: Maybe<Scalars['Boolean']>;
  createWorkflowChecklist?: Maybe<CreateMozartChecklistResult>;
  deleteChecklistProblem?: Maybe<Scalars['Boolean']>;
  discardChecklist?: Maybe<Scalars['Boolean']>;
  discardEpisode?: Maybe<ActionEpisodeResult>;
  discardMozartWorkflow?: Maybe<MozartActionResult>;
  episodeAutoAssign?: Maybe<AutoAssignStatus>;
  finishChecklist?: Maybe<FinishChecklistResultModel>;
  finishChecklistLater?: Maybe<Scalars['Boolean']>;
  finishMozartWorkflowChecklist?: Maybe<MozartActionResult>;
  forwardOwner?: Maybe<ActionEpisodeResult>;
  insertMozartWorkflowOOTStep?: Maybe<MozartActionResult>;
  lockEpisode?: Maybe<ActionEpisodeResult>;
  lockMozartAction?: Maybe<MozartActionResult>;
  mergeAttachments?: Maybe<MergeResult>;
  mergeFiles?: Maybe<MergeResult>;
  moveAttachments?: Maybe<Scalars['Boolean']>;
  onLetterPrint?: Maybe<Scalars['Boolean']>;
  onSendFax: OnSendFaxResultModel;
  removeChecklistAction?: Maybe<Scalars['Boolean']>;
  reopenEpisode?: Maybe<Scalars['Boolean']>;
  retryMozartWorkflow?: Maybe<MozartActionResult>;
  revertMozartWorkflowToPreviousStep?: Maybe<MozartActionResult>;
  saveAllEntities?: Maybe<Scalars['Boolean']>;
  saveColumnWidth?: Maybe<Scalars['Boolean']>;
  saveQuickNote?: Maybe<FinishChecklistResultModel>;
  saveRecentSearches?: Maybe<Scalars['Boolean']>;
  selectAction?: Maybe<Scalars['Boolean']>;
  sendToPrintShop?: Maybe<Scalars['Boolean']>;
  setCurrentUserPreference?: Maybe<Scalars['Boolean']>;
  setPreference?: Maybe<Scalars['Boolean']>;
  startMozartWorkflow?: Maybe<MozartStartWorkflowResult>;
  unlockEpisode?: Maybe<ActionEpisodeResult>;
  unlockMozartAction?: Maybe<MozartActionResult>;
  updateActionValue?: Maybe<Scalars['Boolean']>;
  updateAttachments?: Maybe<Scalars['Boolean']>;
  updateChecklist?: Maybe<Scalars['Int']>;
  updateChecklistProblem?: Maybe<Scalars['Boolean']>;
  updateChecklistScenario?: Maybe<CreateChecklistResultModel>;
  updateEntityReminders?: Maybe<Scalars['Boolean']>;
  updateEpisodeSummary?: Maybe<ActionEpisodeResult>;
  updateFromEpisodeToChecklist?: Maybe<UpdateChecklistResult>;
  updateLastCompletedChecklist?: Maybe<UpdateChecklistResult>;
  updatePatientCareGaps?: Maybe<Scalars['Boolean']>;
  updateUserPreferences?: Maybe<UniversalResultModel>;
};

export type MutationAddAttachmentsArgs = {
  model: UpdateAttachmentDtoInput;
};

export type MutationAddChecklistActionArgs = {
  actionId: Scalars['String'];
  checklistId: Scalars['Int'];
};

export type MutationAddChecklistProblemsArgs = {
  checklistId: Scalars['Int'];
  problemIds: Array<Scalars['Int']>;
};

export type MutationAddEpisodeBalancerCriteriaToQueueArgs = {
  episodeBalancerCriteria: EpisodeBalancerCriteriaInput;
};

export type MutationAddProviderToRecentProvidersArgs = {
  providerId: Scalars['Int'];
};

export type MutationAddQuickNoteActionArgs = {
  orderableId: Scalars['Int'];
};

export type MutationAmendChecklistArgs = {
  checklistId?: InputMaybe<Scalars['Int']>;
};

export type MutationAttachFromEpisodeToEpisodeArgs = {
  episodeId: Scalars['Int'];
  targetEpisodeId?: InputMaybe<Scalars['Int']>;
  targetPatientId: Scalars['Int'];
};

export type MutationChangeReminderStateArgs = {
  model?: InputMaybe<ChangeReminderStateDtoInput>;
};

export type MutationCloseEpisodeArgs = {
  completeReminder?: InputMaybe<Scalars['Boolean']>;
  episodeId: Scalars['Int'];
};

export type MutationContinueMozartStepArgs = {
  workflowInstanceId: Scalars['UUID'];
};

export type MutationContinueMozartWorkflowArgs = {
  id: Scalars['UUID'];
  request: ContinueMozartWorkflowRequestInput;
};

export type MutationCreateAndViewWorkflowChecklistArgs = {
  actionId: Scalars['UUID'];
  id: Scalars['UUID'];
  scenarioId: Scalars['Int'];
};

export type MutationCreateChecklistArgs = {
  data?: InputMaybe<CreateChecklistModelInput>;
};

export type MutationCreateOrUpdateCarePlanArgs = {
  model?: InputMaybe<CreateOrUpdateCarePlanActionContextInput>;
};

export type MutationCreateOrUpdatePatientArgs = {
  patient?: InputMaybe<PatientInfoInput>;
};

export type MutationCreateWorkflowChecklistArgs = {
  id: Scalars['UUID'];
  scenarioId: Scalars['Int'];
};

export type MutationDeleteChecklistProblemArgs = {
  checklistId: Scalars['Int'];
  problemId: Scalars['Int'];
};

export type MutationDiscardChecklistArgs = {
  checklistId?: InputMaybe<Scalars['Int']>;
  connectionId?: InputMaybe<Scalars['String']>;
};

export type MutationDiscardEpisodeArgs = {
  episodeId: Scalars['Int'];
};

export type MutationDiscardMozartWorkflowArgs = {
  checklistId: Scalars['Int'];
  id: Scalars['UUID'];
};

export type MutationEpisodeAutoAssignArgs = {
  autoAssignModel: AutoAssignInputModelInput;
};

export type MutationFinishChecklistArgs = {
  data?: InputMaybe<FinishChecklistModelInput>;
};

export type MutationFinishChecklistLaterArgs = {
  checklistId?: InputMaybe<Scalars['Int']>;
  connectionId?: InputMaybe<Scalars['String']>;
  entryStep?: InputMaybe<Scalars['String']>;
};

export type MutationFinishMozartWorkflowChecklistArgs = {
  id: Scalars['UUID'];
};

export type MutationForwardOwnerArgs = {
  episodeId: Scalars['Int'];
  userId?: InputMaybe<Scalars['Int']>;
};

export type MutationInsertMozartWorkflowOotStepArgs = {
  id: Scalars['UUID'];
  workflowInstanceOOTStepId: Scalars['UUID'];
};

export type MutationLockEpisodeArgs = {
  episodeId: Scalars['Int'];
};

export type MutationLockMozartActionArgs = {
  actionInstanceId: Scalars['UUID'];
  workflowInstanceId: Scalars['UUID'];
};

export type MutationMergeAttachmentsArgs = {
  attachmentIds?: InputMaybe<Array<Scalars['Int']>>;
  fileIds?: InputMaybe<Array<Scalars['String']>>;
};

export type MutationMergeFilesArgs = {
  fileIds?: InputMaybe<Array<Scalars['String']>>;
};

export type MutationMoveAttachmentsArgs = {
  model: Array<InputMaybe<MoveAttachmentsModelInput>>;
};

export type MutationOnLetterPrintArgs = {
  model: LetterPreviewModelInput;
};

export type MutationOnSendFaxArgs = {
  model: OnSendFaxModelInput;
};

export type MutationRemoveChecklistActionArgs = {
  actionId: Scalars['String'];
  checklistId: Scalars['Int'];
};

export type MutationReopenEpisodeArgs = {
  episodeId: Scalars['Int'];
};

export type MutationRetryMozartWorkflowArgs = {
  id: Scalars['UUID'];
};

export type MutationRevertMozartWorkflowToPreviousStepArgs = {
  stepId: Scalars['UUID'];
  workflowInstanceId: Scalars['UUID'];
};

export type MutationSaveAllEntitiesArgs = {
  model?: InputMaybe<EntitiesInfoInput>;
};

export type MutationSaveColumnWidthArgs = {
  model: ColumnWidthModelInput;
};

export type MutationSaveQuickNoteArgs = {
  data?: InputMaybe<SaveQuickNoteDialogDtoInput>;
};

export type MutationSaveRecentSearchesArgs = {
  currentValue: Scalars['String'];
};

export type MutationSelectActionArgs = {
  data?: InputMaybe<SelectActionModelInput>;
};

export type MutationSendToPrintShopArgs = {
  model: LetterPreviewModelInput;
};

export type MutationSetCurrentUserPreferenceArgs = {
  name: Scalars['String'];
  value: Scalars['String'];
};

export type MutationSetPreferenceArgs = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type MutationStartMozartWorkflowArgs = {
  request?: InputMaybe<StartMozartWorkflowRequestInput>;
};

export type MutationUnlockEpisodeArgs = {
  checklistId?: InputMaybe<Scalars['Int']>;
  episodeId?: InputMaybe<Scalars['Int']>;
};

export type MutationUnlockMozartActionArgs = {
  actionInstanceId: Scalars['UUID'];
  workflowInstanceId: Scalars['UUID'];
};

export type MutationUpdateActionValueArgs = {
  data?: InputMaybe<UpdateActionValueModelInput>;
};

export type MutationUpdateAttachmentsArgs = {
  model: UpdateAttachmentsModelInput;
};

export type MutationUpdateChecklistArgs = {
  checklistId?: InputMaybe<Scalars['Int']>;
};

export type MutationUpdateChecklistProblemArgs = {
  problem: UpdateChecklistProblemModelInput;
};

export type MutationUpdateChecklistScenarioArgs = {
  data?: InputMaybe<UpdateChecklistModelInput>;
};

export type MutationUpdateEntityRemindersArgs = {
  entitiesReminders: Array<InputMaybe<UpdateReminderDtoInput>>;
};

export type MutationUpdateEpisodeSummaryArgs = {
  episodeId: Scalars['Int'];
  summary: Scalars['String'];
};

export type MutationUpdateFromEpisodeToChecklistArgs = {
  episodeId: Scalars['Int'];
  targetEpisodeId: Scalars['Int'];
};

export type MutationUpdateLastCompletedChecklistArgs = {
  episodeId?: InputMaybe<Scalars['Int']>;
  patientId: Scalars['Int'];
};

export type MutationUpdatePatientCareGapsArgs = {
  model?: InputMaybe<UpdatePatientCareGapsModelInput>;
};

export type MutationUpdateUserPreferencesArgs = {
  data?: InputMaybe<UserPreferencesViewModelInput>;
};

export type NoteAutocomplete = {
  __typename?: 'NoteAutocomplete';
  searchText: Scalars['String'];
};

export type OnSendFaxModelInput = {
  attachmentId?: InputMaybe<Scalars['Int']>;
  checklistHtml?: InputMaybe<Scalars['String']>;
  checklistId: Scalars['Int'];
  episodeId: Scalars['Int'];
  isFaxRetry: Scalars['Boolean'];
  isFromAttachment: Scalars['Boolean'];
  letters?: InputMaybe<Array<InputMaybe<LetterDtoInput>>>;
  note?: InputMaybe<Scalars['String']>;
  patientId: Scalars['Int'];
  recipientFaxNumber: Scalars['String'];
  recipientTo: Scalars['String'];
  senderFaxNumber: Scalars['String'];
  senderFrom: Scalars['String'];
  senderRespondTo: Scalars['Int'];
};

export type OnSendFaxResultModel = {
  __typename?: 'OnSendFaxResultModel';
  attachment?: Maybe<Attachment>;
  isFaxSent: Scalars['Boolean'];
};

export type OrderSetViewModel = {
  __typename?: 'OrderSetViewModel';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  problems?: Maybe<Array<Problem>>;
};

export enum OrderableDependentConditionType {
  ByAction = 'BY_ACTION',
  ByComponent = 'BY_COMPONENT',
}

export enum PaRequirement {
  InternalMaybeRequired = 'INTERNAL_MAYBE_REQUIRED',
  InternalRequired = 'INTERNAL_REQUIRED',
  NonCovered = 'NON_COVERED',
  NotRequired = 'NOT_REQUIRED',
  VendorRequired = 'VENDOR_REQUIRED',
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type Patient = IAuditable & {
  __typename?: 'Patient';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  age?: Maybe<Scalars['Int']>;
  allergies?: Maybe<Scalars['String']>;
  alternatePhone?: Maybe<Scalars['String']>;
  alternatePhone2?: Maybe<Scalars['String']>;
  careSite?: Maybe<CareSite>;
  careStage?: Maybe<LookupValue>;
  careTeamMembers?: Maybe<Array<PatientCareTeamMember>>;
  cellPhone?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  conditions?: Maybe<Array<Maybe<PatientCondition>>>;
  createdBy: Scalars['Int'];
  createdByUser?: Maybe<User>;
  createdOn?: Maybe<Scalars['DateTime']>;
  dOB?: Maybe<Scalars['Date']>;
  dOD?: Maybe<Scalars['Date']>;
  descriptor?: Maybe<Scalars['String']>;
  disposition?: Maybe<LookupValue>;
  email?: Maybe<Scalars['String']>;
  entityAttributes?: Maybe<Array<Maybe<EntityAttribute>>>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  fullSearch?: Maybe<Scalars['String']>;
  gender?: Maybe<LookupValue>;
  guardian?: Maybe<Scalars['String']>;
  homePhone?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  language?: Maybe<LookupValue>;
  lastEntry?: Maybe<Scalars['Any']>;
  lastName?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  mRN: Scalars['String'];
  mRN2?: Maybe<Scalars['String']>;
  mRN3?: Maybe<Scalars['String']>;
  mRN4?: Maybe<Scalars['String']>;
  mRN5?: Maybe<Scalars['String']>;
  medicationClaims?: Maybe<Array<Maybe<PatientMedicationClaim>>>;
  medicationLastReviewedBy?: Maybe<User>;
  medicationLastReviewedOn?: Maybe<Scalars['Date']>;
  middleName?: Maybe<Scalars['String']>;
  owner?: Maybe<User>;
  payor?: Maybe<LookupValue>;
  populations?: Maybe<Array<Maybe<LookupValue>>>;
  preferredAddress?: Maybe<PreferredAddress>;
  preferredEmail?: Maybe<PreferredEmail>;
  preferredPhone?: Maybe<PreferredPhone>;
  preferredPhoneNumber?: Maybe<Scalars['String']>;
  primaryCareProvider?: Maybe<Provider>;
  priority?: Maybe<LookupValue>;
  program?: Maybe<LookupValue>;
  provider?: Maybe<Provider>;
  reminders?: Maybe<Array<Maybe<BaseReminder>>>;
  riskScore?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<LookupValue>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedByUser?: Maybe<User>;
  updatedOn?: Maybe<Scalars['DateTime']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type PatientCareGap = {
  __typename?: 'PatientCareGap';
  careGap?: Maybe<Scalars['String']>;
  createdBy: SimpleLookup;
  createdOn: Scalars['DateTime'];
  id: Scalars['Int'];
  patientId: Scalars['Int'];
  priority?: Maybe<SimpleLookup>;
  status?: Maybe<SimpleLookup>;
  updatedBy?: Maybe<SimpleLookup>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type PatientCareGapInput = {
  careGap: Scalars['String'];
  createdBy: SimpleLookupInput;
  createdOn: Scalars['DateTime'];
  id: Scalars['Int'];
  patientId?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<SimpleLookupInput>;
  status?: InputMaybe<SimpleLookupInput>;
  updatedBy?: InputMaybe<SimpleLookupInput>;
  updatedOn?: InputMaybe<Scalars['DateTime']>;
};

export type PatientCarePlanFilterModelInput = {
  episodeId: Scalars['ID'];
  patientId: Scalars['ID'];
};

export enum PatientCarePlanStatusFilterViewModel {
  Active = 'ACTIVE',
  All = 'ALL',
  Inactive = 'INACTIVE',
}

export type PatientCarePlanViewModel = {
  __typename?: 'PatientCarePlanViewModel';
  checklist: ChecklistViewModel;
  problems: Array<ChecklistProblemViewModel>;
};

export type PatientCareTeamCareSiteUserViewModel = {
  __typename?: 'PatientCareTeamCareSiteUserViewModel';
  id: Scalars['Int'];
  isOtherCareSite: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  roleName?: Maybe<Scalars['String']>;
  stateGreen: Scalars['Int'];
  stateOrange: Scalars['Int'];
  stateRed: Scalars['Int'];
  stateYellow: Scalars['Int'];
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type PatientCareTeamFamilyViewModel = {
  __typename?: 'PatientCareTeamFamilyViewModel';
  consentToShare: Scalars['Boolean'];
  id: Scalars['Int'];
  isCaregiver: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  relationshipId?: Maybe<Scalars['Int']>;
};

export type PatientCareTeamMember = {
  __typename?: 'PatientCareTeamMember';
  id: Scalars['ID'];
  member: User;
  note?: Maybe<Scalars['String']>;
};

export type PatientCareTeamMemberViewModel = {
  __typename?: 'PatientCareTeamMemberViewModel';
  id: Scalars['Int'];
  isOtherCareSite: Scalars['Boolean'];
  isOwner: Scalars['Boolean'];
  member: User;
  note?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type PatientCareTeamOwnerViewModel = {
  __typename?: 'PatientCareTeamOwnerViewModel';
  name: Scalars['String'];
  required: Scalars['Boolean'];
};

export type PatientCareTeamProviderViewModel = {
  __typename?: 'PatientCareTeamProviderViewModel';
  id: Scalars['Int'];
  isPrimary: Scalars['Boolean'];
  note?: Maybe<Scalars['String']>;
  provider: Provider;
};

export type PatientCareTeamTabViewModel = {
  __typename?: 'PatientCareTeamTabViewModel';
  family: Array<PatientCareTeamFamilyViewModel>;
  isCanChangeOwner: Scalars['Boolean'];
  owner: PatientCareTeamOwnerViewModel;
  providers: Array<PatientCareTeamProviderViewModel>;
  relationships: Array<LookupValue>;
  specialties: Array<LookupValue>;
  teamMembers: Array<PatientCareTeamMemberViewModel>;
  users: Array<PatientCareTeamCareSiteUserViewModel>;
};

export type PatientCasesFilterModelInput = {
  ascDesc?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patientId: Scalars['Int'];
  selectedEpisode?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Scalars['String']>;
  types?: InputMaybe<Scalars['String']>;
};

export type PatientCasesViewModel = {
  __typename?: 'PatientCasesViewModel';
  episodes?: Maybe<Array<EpisodeBaseViewModel>>;
  pager?: Maybe<PatientEpisodesTablePagerViewModel>;
};

export type PatientChecklistOrderSetViewModel = {
  __typename?: 'PatientChecklistOrderSetViewModel';
  backcolor: Scalars['String'];
  forecolor: Scalars['String'];
  name: Scalars['String'];
  title: Scalars['String'];
};

export type PatientChecklistViewModel = {
  __typename?: 'PatientChecklistViewModel';
  containsAttachments: Scalars['Boolean'];
  createdByName: Scalars['String'];
  createdOn: Scalars['DateTime'];
  id: Scalars['Int'];
  operations?: Maybe<Array<Maybe<ChecklistOperation>>>;
  orderSets?: Maybe<Array<Maybe<PatientChecklistOrderSetViewModel>>>;
  status?: Maybe<ChecklistStatus>;
};

export type PatientClaimDto = {
  __typename?: 'PatientClaimDto';
  billingProviderName?: Maybe<Scalars['String']>;
  billingProviderTitle?: Maybe<Scalars['String']>;
  checkDate?: Maybe<Scalars['Date']>;
  claimDate?: Maybe<Scalars['Date']>;
  claimEffectuated?: Maybe<Scalars['Boolean']>;
  claimId?: Maybe<Scalars['String']>;
  claimReceivedDate?: Maybe<Scalars['Date']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  diagnosisCodes?: Maybe<Array<Maybe<MedicationCode>>>;
  id: Scalars['Int'];
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  memberBenefitNetwork?: Maybe<Scalars['String']>;
  memberResponsibility?: Maybe<Scalars['Decimal']>;
  paidAmount?: Maybe<Scalars['Decimal']>;
  performingProvider?: Maybe<Scalars['String']>;
  procedureCodes?: Maybe<Array<Maybe<MedicationCode>>>;
  provider?: Maybe<Provider>;
  serviceFrom?: Maybe<Scalars['Date']>;
  serviceLocationName?: Maybe<Scalars['String']>;
  serviceTo?: Maybe<Scalars['Date']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type PatientClaimDtoSearchResult = {
  __typename?: 'PatientClaimDtoSearchResult';
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  rows: Array<PatientClaimDto>;
  totalCount: Scalars['Int'];
};

export type PatientCondition = IAuditable & {
  __typename?: 'PatientCondition';
  code?: Maybe<MedicationCode>;
  createdBy: Scalars['Int'];
  createdByUser?: Maybe<User>;
  createdOn?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isAdded: Scalars['Boolean'];
  note?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
  updatedBy?: Maybe<Scalars['Int']>;
  updatedByUser?: Maybe<User>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type PatientContactsViewModel = {
  __typename?: 'PatientContactsViewModel';
  addresses: Array<AddressDto>;
  emails: Array<EmailDto>;
  isReadOnly: Scalars['Boolean'];
  isSystemUser: Scalars['Boolean'];
  language?: Maybe<LookupValue>;
  languageBarriers: Array<LanguageBarrierDto>;
  phones: Array<PhoneDto>;
};

export type PatientDetailsSubTabDefinition = {
  __typename?: 'PatientDetailsSubTabDefinition';
  isVisible: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
};

export type PatientDetailsTabDefinition = {
  __typename?: 'PatientDetailsTabDefinition';
  glossaryName?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  isVisible: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  readOnly: Scalars['Boolean'];
  subTabDefinitions?: Maybe<Array<Maybe<PatientDetailsSubTabDefinition>>>;
  title?: Maybe<Scalars['String']>;
};

export type PatientEditInsuranceCurrentViewModel = {
  __typename?: 'PatientEditInsuranceCurrentViewModel';
  effective?: Maybe<Scalars['String']>;
  insuranceId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  term?: Maybe<Scalars['String']>;
};

export type PatientEditInsuranceHistoryViewModel = {
  __typename?: 'PatientEditInsuranceHistoryViewModel';
  benefitDescription?: Maybe<Scalars['String']>;
  benefitNo?: Maybe<Scalars['String']>;
  contract?: Maybe<Scalars['String']>;
  disenrolled?: Maybe<Scalars['String']>;
  effective?: Maybe<Scalars['String']>;
  gapWithNextDate: Scalars['Boolean'];
  gapWithPreviousDate: Scalars['Boolean'];
  group?: Maybe<Scalars['String']>;
  inconsistentDates: Scalars['Boolean'];
  lob?: Maybe<Scalars['String']>;
  newGroup: Scalars['Boolean'];
  number?: Maybe<Scalars['String']>;
};

export type PatientEditRiskTabViewModel = {
  __typename?: 'PatientEditRiskTabViewModel';
  patientCareGap: Array<PatientCareGap>;
  patientRiskClinicalClassification: Array<PatientRiskClinicalClassification>;
  patientRiskHistory: Array<PatientRisk>;
};

export type PatientEditTagsTabViewModel = {
  __typename?: 'PatientEditTagsTabViewModel';
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  sortOrder?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
};

export type PatientEpisodesFilterModelInput = {
  ascDesc?: InputMaybe<Scalars['String']>;
  checklistId?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patientId: Scalars['Int'];
  readonly?: InputMaybe<Scalars['Boolean']>;
  selectedEpisode?: InputMaybe<Scalars['Int']>;
  showCounterColumn?: InputMaybe<Scalars['Boolean']>;
  showTatColumn?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Scalars['String']>;
  types?: InputMaybe<Scalars['String']>;
};

export type PatientEpisodesTabModel = {
  __typename?: 'PatientEpisodesTabModel';
  episodeListState?: Maybe<Scalars['String']>;
  episodeTypes?: Maybe<Array<Maybe<EpisodeType>>>;
  selectedSubTab?: Maybe<Scalars['String']>;
  subTabs?: Maybe<Array<Maybe<PatientDetailsSubTabDefinition>>>;
};

export type PatientEpisodesTablePagerViewModel = {
  __typename?: 'PatientEpisodesTablePagerViewModel';
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  pagesCount?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PatientEpisodesViewModel = {
  __typename?: 'PatientEpisodesViewModel';
  episodes?: Maybe<Array<EpisodeBaseViewModel>>;
  pager?: Maybe<PatientEpisodesTablePagerViewModel>;
  readonly: Scalars['Boolean'];
  showCounterColumn: Scalars['Boolean'];
  showTatColumn: Scalars['Boolean'];
};

export type PatientEpisodesWithMozartInfoViewModel = {
  __typename?: 'PatientEpisodesWithMozartInfoViewModel';
  episodes?: Maybe<Array<EpisodeWithMozartInfoViewModel>>;
  pager?: Maybe<PatientEpisodesTablePagerViewModel>;
  readonly: Scalars['Boolean'];
  showCounterColumn: Scalars['Boolean'];
  showTatColumn: Scalars['Boolean'];
};

export type PatientInfoInput = {
  addresses?: InputMaybe<Array<InputMaybe<AddressDtoInput>>>;
  allergies?: InputMaybe<Scalars['String']>;
  areMedsUpdated: Scalars['Boolean'];
  areProvidersUpdated: Scalars['Boolean'];
  areTagsUpdated: Scalars['Boolean'];
  areTeamMembersUpdated: Scalars['Boolean'];
  conditions?: InputMaybe<Array<InputMaybe<ConditionDtoInput>>>;
  deniesActiveMedications?: InputMaybe<Scalars['Boolean']>;
  emails?: InputMaybe<Array<InputMaybe<EmailDtoInput>>>;
  family?: InputMaybe<Array<FamilyMemberDtoInput>>;
  id: Scalars['ID'];
  isFamilyUpdated: Scalars['Boolean'];
  medications?: InputMaybe<Array<InputMaybe<PatientMedicationDtoInInput>>>;
  noKnownDrugAllergies?: InputMaybe<Scalars['Boolean']>;
  phones?: InputMaybe<Array<InputMaybe<PhoneDtoInput>>>;
  providers?: InputMaybe<Array<ProviderMemberDtoInput>>;
  reviewed?: InputMaybe<Scalars['Boolean']>;
  tags?: InputMaybe<Array<InputMaybe<TagDtoInput>>>;
  teamMembers?: InputMaybe<Array<CareTeamMemberDtoInput>>;
  values?: InputMaybe<Array<InputMaybe<KeyValuePairOfStringAndStringInput>>>;
};

export type PatientInsuranceInfo = {
  __typename?: 'PatientInsuranceInfo';
  contract?: Maybe<Scalars['String']>;
  effective?: Maybe<Scalars['DateTime']>;
  group?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lob?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  terminate?: Maybe<Scalars['DateTime']>;
};

export type PatientInsuranceStatusActionContextInput = {
  patientId: Scalars['Int'];
  reqEndDate: Scalars['Date'];
  reqStartDate: Scalars['Date'];
};

export type PatientInsuranceTabViewModel = {
  __typename?: 'PatientInsuranceTabViewModel';
  insuranceCurrent: Array<PatientEditInsuranceCurrentViewModel>;
  insuranceHistory: Array<PatientEditInsuranceHistoryViewModel>;
};

export type PatientMedicationClaim = {
  __typename?: 'PatientMedicationClaim';
  billingProvider?: Maybe<PatientMedicationClaimBillingProvider>;
  claimId?: Maybe<Scalars['String']>;
  dispenseDate: Scalars['Date'];
  id: Scalars['Int'];
  medication?: Maybe<Scalars['String']>;
  paidAmount: Scalars['Decimal'];
  pharmacy?: Maybe<Scalars['String']>;
  provider?: Maybe<Provider>;
  quantityDispensed?: Maybe<Scalars['Float']>;
};

export type PatientMedicationClaimBillingProvider = {
  __typename?: 'PatientMedicationClaimBillingProvider';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type PatientMedicationDtoInInput = {
  byVerbalReport?: InputMaybe<Scalars['Boolean']>;
  changed: Scalars['Boolean'];
  dateFilled?: InputMaybe<Scalars['Date']>;
  deleted: Scalars['Boolean'];
  endDate?: InputMaybe<Scalars['Date']>;
  id: Scalars['Int'];
  instructions?: InputMaybe<Scalars['String']>;
  mdRecommended?: InputMaybe<Scalars['Boolean']>;
  medication?: InputMaybe<Scalars['String']>;
  medicationId?: InputMaybe<Scalars['Int']>;
  note?: InputMaybe<Scalars['String']>;
  patientState: PatientMedicationStatus;
  patientUse?: InputMaybe<PatientMedicationPatientUse>;
  pharmacy?: InputMaybe<Scalars['String']>;
  pharmacyFax?: InputMaybe<Scalars['String']>;
  pharmacyPhone?: InputMaybe<Scalars['String']>;
  providerId?: InputMaybe<Scalars['Int']>;
  quantityFilled?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
  refills?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
  type: PatientMedicationType;
};

export enum PatientMedicationPatientUse {
  MemberDirected = 'MEMBER_DIRECTED',
  NotTaking = 'NOT_TAKING',
  TakesasPrescribed = 'TAKESAS_PRESCRIBED',
  TakesDifferently = 'TAKES_DIFFERENTLY',
}

export enum PatientMedicationStatus {
  Active = 'ACTIVE',
  Discontinued = 'DISCONTINUED',
}

export enum PatientMedicationStatusFilter {
  Active = 'ACTIVE',
  All = 'ALL',
  Discontinued = 'DISCONTINUED',
}

export enum PatientMedicationType {
  Otc = 'OTC',
  Prescription = 'PRESCRIPTION',
}

export type PatientOpIpAuthorizationsViewModel = {
  __typename?: 'PatientOpIpAuthorizationsViewModel';
  inpatientDate?: Maybe<Scalars['Date']>;
  inpatientDiagnosisCode?: Maybe<Scalars['String']>;
  inpatientDiagnosisDescription?: Maybe<Scalars['String']>;
  inpatientStatus?: Maybe<Scalars['String']>;
  outpatientDate?: Maybe<Scalars['Date']>;
  outpatientDiagnosisCode?: Maybe<Scalars['String']>;
  outpatientDiagnosisDescription?: Maybe<Scalars['String']>;
  outpatientStatus?: Maybe<Scalars['String']>;
};

export type PatientRisk = {
  __typename?: 'PatientRisk';
  acuityLevel: Scalars['String'];
  brx: Scalars['Decimal'];
  createdOn: Scalars['String'];
  eR: Scalars['Decimal'];
  id: Scalars['Int'];
  ip: Scalars['Decimal'];
  med: Scalars['Decimal'];
  modelName: Scalars['String'];
  op: Scalars['Decimal'];
  oth: Scalars['Decimal'];
  patientId: Scalars['Int'];
  pcp: Scalars['Decimal'];
  phy: Scalars['Decimal'];
  risingRiskFlag: Scalars['Decimal'];
  rx: Scalars['Decimal'];
  tot: Scalars['Decimal'];
};

export type PatientRiskClinicalClassification = {
  __typename?: 'PatientRiskClinicalClassification';
  clinicalLabel: Scalars['String'];
  count: Scalars['Int'];
  patientId: Scalars['Int'];
};

export type PatientTimelineAttachmentRowViewModel = {
  __typename?: 'PatientTimelineAttachmentRowViewModel';
  attachmentDescription?: Maybe<Scalars['String']>;
  attachmentDetails?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  externalReferenceId?: Maybe<Scalars['String']>;
  finishedOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  individual?: Maybe<Scalars['String']>;
  isFax?: Maybe<Scalars['Boolean']>;
  isLetter?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  typeClass?: Maybe<Scalars['String']>;
  typeTitle?: Maybe<Scalars['String']>;
};

export type PatientTimelineChecklistRowViewModel = {
  __typename?: 'PatientTimelineChecklistRowViewModel';
  checklistStatus?: Maybe<ChecklistStatus>;
  containsAttachments?: Maybe<Scalars['Boolean']>;
  date?: Maybe<Scalars['String']>;
  episodeNumber?: Maybe<Scalars['String']>;
  finishedOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  individual?: Maybe<Scalars['String']>;
  operations?: Maybe<Array<Maybe<Scalars['String']>>>;
  orderSets?: Maybe<Array<Maybe<ChecklistOrderSetRowView>>>;
  type?: Maybe<Scalars['String']>;
  workflowStepName?: Maybe<Scalars['String']>;
};

export type PatientTimelineReminderRowViewModel = {
  __typename?: 'PatientTimelineReminderRowViewModel';
  date?: Maybe<Scalars['String']>;
  descriptionImage?: Maybe<Scalars['String']>;
  finishedOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  individual?: Maybe<Scalars['String']>;
  opened?: Maybe<Scalars['Boolean']>;
  reminderDescription?: Maybe<Scalars['String']>;
  reminderDetails?: Maybe<Scalars['String']>;
  reminderStatus?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type Pharmacy = ILookupValue & {
  __typename?: 'Pharmacy';
  id: Scalars['Int'];
  isDeleted: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
};

export type PhoneDto = {
  __typename?: 'PhoneDto';
  isOk: Scalars['Boolean'];
  isPrimary: Scalars['Boolean'];
  number?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type PhoneDtoInput = {
  isOk: Scalars['Boolean'];
  isPrimary: Scalars['Boolean'];
  number?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export enum PreferredAddress {
  Alternate = 'ALTERNATE',
  Main = 'MAIN',
  None = 'NONE',
  Third = 'THIRD',
}

export enum PreferredEmail {
  Alternate = 'ALTERNATE',
  Main = 'MAIN',
  None = 'NONE',
}

export enum PreferredPhone {
  Alternate = 'ALTERNATE',
  Alternate2 = 'ALTERNATE2',
  Cell = 'CELL',
  Home = 'HOME',
  None = 'NONE',
}

export enum PrintShopStatus {
  Error = 'ERROR',
  Success = 'SUCCESS',
  Undefined = 'UNDEFINED',
  Waiting = 'WAITING',
}

export type PriorAuthCodesActionModel = {
  __typename?: 'PriorAuthCodesActionModel';
  id: Scalars['Int'];
  notificationMessage?: Maybe<Scalars['String']>;
  priorAuthCodesActionValues: Array<PriorAuthCodesActionValue>;
  reqEndDate?: Maybe<Scalars['Date']>;
  reqStartDate?: Maybe<Scalars['Date']>;
  status?: Maybe<PriorAuthInsuranceStatus>;
};

export type PriorAuthCodesActionValue = {
  __typename?: 'PriorAuthCodesActionValue';
  byCodeFound: Scalars['Boolean'];
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  externalURL?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPARequired: Scalars['Boolean'];
  notificationMessage?: Maybe<Scalars['String']>;
  primaryMessage?: Maybe<Scalars['String']>;
  priorAuthRequirement?: Maybe<PaRequirement>;
  secondaryMessage?: Maybe<Scalars['String']>;
};

export type PriorAuthCodesOrderableOptions = {
  __typename?: 'PriorAuthCodesOrderableOptions';
  allowedCodeCategory: MedicationCodeCategoryType;
  category?: Maybe<LookupValue>;
  codeLabel: Scalars['String'];
  descriptionLabel: Scalars['String'];
  disAllowedCodeMessage?: Maybe<Scalars['String']>;
  itemType: MedicationCodeItemType;
  maxAllowed: Scalars['Int'];
  minRequired: Scalars['Int'];
  pAAllowSelection: Scalars['Boolean'];
  pAShowCheckPolicy: Scalars['Boolean'];
  type: MedicationCodeType;
};

export enum PriorAuthInsuranceStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type Problem = IAuditable &
  ILookupValue & {
    __typename?: 'Problem';
    createdBy: Scalars['Int'];
    createdByUser?: Maybe<User>;
    createdOn?: Maybe<Scalars['DateTime']>;
    description?: Maybe<Scalars['String']>;
    id: Scalars['Int'];
    isActive: Scalars['Boolean'];
    isDeleted: Scalars['Boolean'];
    name?: Maybe<Scalars['String']>;
    priority?: Maybe<LookupValue>;
    updatedBy?: Maybe<Scalars['Int']>;
    updatedByUser?: Maybe<User>;
    updatedOn?: Maybe<Scalars['DateTime']>;
  };

export type Provider = IAuditable &
  ILookupValue & {
    __typename?: 'Provider';
    benefitPlansNo?: Maybe<Array<Maybe<Scalars['String']>>>;
    careSite?: Maybe<CareSite>;
    createdBy: Scalars['Int'];
    createdByUser?: Maybe<User>;
    createdOn?: Maybe<Scalars['DateTime']>;
    email?: Maybe<Scalars['String']>;
    ethnicity?: Maybe<LookupValue>;
    firstName?: Maybe<Scalars['String']>;
    fullSearch?: Maybe<Scalars['String']>;
    gender?: Maybe<LookupValue>;
    id: Scalars['Int'];
    insuranceId?: Maybe<Scalars['String']>;
    isDeleted?: Maybe<Scalars['Boolean']>;
    isOutOfState?: Maybe<Scalars['Boolean']>;
    language?: Maybe<Scalars['String']>;
    lastName?: Maybe<Scalars['String']>;
    lineOfBusiness?: Maybe<Array<Maybe<Scalars['String']>>>;
    middleName?: Maybe<Scalars['String']>;
    nPI?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    notes?: Maybe<Scalars['String']>;
    preferredEmail?: Maybe<Scalars['String']>;
    preferredFax?: Maybe<Scalars['String']>;
    preferredPhone?: Maybe<Scalars['String']>;
    providerAddresses?: Maybe<Array<Maybe<ProviderAddress>>>;
    providerId?: Maybe<Scalars['String']>;
    speciality?: Maybe<LookupValue>;
    taxID?: Maybe<Scalars['String']>;
    type?: Maybe<LookupValue>;
    updatedBy?: Maybe<Scalars['Int']>;
    updatedByUser?: Maybe<User>;
    updatedOn?: Maybe<Scalars['DateTime']>;
  };

export type ProviderAddress = {
  __typename?: 'ProviderAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  practiceName?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type ProviderDetailsViewModel = {
  __typename?: 'ProviderDetailsViewModel';
  addressIds: Array<Scalars['String']>;
  addressIdsLabel: Scalars['String'];
  addresses: Array<Scalars['String']>;
  dPG?: Maybe<LookupValue>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  npi?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  parNotes: Scalars['String'];
  practiceNames: Array<Scalars['String']>;
  practiceNamesLabel: Scalars['String'];
  seamless?: Maybe<Scalars['Boolean']>;
  taxId?: Maybe<Scalars['String']>;
  types: Array<Scalars['String']>;
};

export type ProviderMemberDtoInput = {
  id: Scalars['Int'];
  isPrimary: Scalars['Boolean'];
  note?: InputMaybe<Scalars['String']>;
};

export type ProviderSearchDialogModel = {
  __typename?: 'ProviderSearchDialogModel';
  favorites?: Maybe<Array<Maybe<Scalars['String']>>>;
  preSelectedProvider?: Maybe<ProviderSearchResultRow>;
  recent?: Maybe<Array<Maybe<RecentProvider>>>;
  rows?: Maybe<Array<Maybe<ProviderSearchResultRow>>>;
  searchInformation?: Maybe<Scalars['String']>;
};

export type ProviderSearchRequestInput = {
  authRole?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  skipAddress?: InputMaybe<Scalars['Boolean']>;
};

export type ProviderSearchResult = {
  __typename?: 'ProviderSearchResult';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<ProviderSearchResultRow>>>;
};

export type ProviderSearchResultRow = {
  __typename?: 'ProviderSearchResultRow';
  address?: Maybe<Scalars['String']>;
  addressId?: Maybe<Scalars['Int']>;
  dPG?: Maybe<LookupValue>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
  nPI?: Maybe<Scalars['String']>;
  par?: Maybe<FoundProviderStatus>;
  parNotes?: Maybe<Scalars['String']>;
  practiceName?: Maybe<Scalars['String']>;
  providerAddressUiId?: Maybe<Scalars['String']>;
  providerId?: Maybe<Scalars['String']>;
  providerType?: Maybe<LookupValue>;
  seamless?: Maybe<Scalars['Boolean']>;
  taxId?: Maybe<Scalars['String']>;
  type?: Maybe<LookupValue>;
};

export type QualityMeasure = {
  __typename?: 'QualityMeasure';
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isReadOnly: Scalars['Boolean'];
  measureLevel?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  userChoice: Scalars['Int'];
};

export enum QuickNoteType {
  Episode = 'EPISODE',
  Patient = 'PATIENT',
}

export type RecentProvider = {
  __typename?: 'RecentProvider';
  added?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Int']>;
};

export type ReferenceAttributeType = {
  __typename?: 'ReferenceAttributeType';
  dataType?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  entityName: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['ID'];
  isBigTable: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  required: Scalars['Boolean'];
  tableName?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  values: Array<LookupValue>;
};

export type ReminderComponent = {
  __typename?: 'ReminderComponent';
  color?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  componentType: Scalars['String'];
  dateDue: Scalars['DateTime'];
  dayType: DayType;
  daysForward: Scalars['Int'];
  daysForwardFrom?: Maybe<Scalars['String']>;
  defaultUserToRemind: ReminderComponentDefaultUser;
  displayOnlyWhenSelected: Scalars['Boolean'];
  dueDateTimeType: DueDateTimeType;
  id: Scalars['String'];
  importanceIdSelected: ReminderComponentImportance;
  isDateDuePending?: Maybe<Scalars['Boolean']>;
  isDueDateTimeDefault?: Maybe<Scalars['Boolean']>;
  isReminderCreated?: Maybe<Scalars['Boolean']>;
  isReminderModified?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  reasonDisplayValue?: Maybe<Scalars['String']>;
  reasonIdSelected: Scalars['Int'];
  reasonImageValue?: Maybe<Scalars['String']>;
  reminder?: Maybe<BaseReminder>;
  reminderId: Scalars['Int'];
  reminderType: ReminderType;
  required: Scalars['Boolean'];
  style?: Maybe<Scalars['String']>;
  teamMemberDisplayValue?: Maybe<Scalars['String']>;
  teamMemberIdSelected: Scalars['Int'];
  uniqueID: Scalars['String'];
  userDefinedId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  width: Scalars['Int'];
};

export enum ReminderComponentDefaultUser {
  CurrentUser = 'CURRENT_USER',
  EpisodeOwner = 'EPISODE_OWNER',
  None = 'NONE',
  PatientOwner = 'PATIENT_OWNER',
}

export enum ReminderComponentImportance {
  Critical = 'CRITICAL',
  High = 'HIGH',
  Low = 'LOW',
}

export type ReminderDtoInput = {
  date: Scalars['DateTime'];
  importanceId: Scalars['Int'];
  note?: InputMaybe<Scalars['String']>;
  reasonId: Scalars['Int'];
  reminderId?: InputMaybe<Scalars['Int']>;
  sortOrder?: InputMaybe<Scalars['Long']>;
  state?: InputMaybe<ReminderStateDto>;
  userId: Scalars['Int'];
};

export type ReminderHistoryData = {
  __typename?: 'ReminderHistoryData';
  id?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<ReminderHistoryRow>>>;
};

export type ReminderHistoryRow = {
  __typename?: 'ReminderHistoryRow';
  action?: Maybe<Scalars['String']>;
  dateFormatted?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
};

export enum ReminderImportance {
  Critical = 'CRITICAL',
  High = 'HIGH',
  Low = 'LOW',
}

export enum ReminderStateDto {
  Completed = 'COMPLETED',
  Deleted = 'DELETED',
  Expired = 'EXPIRED',
  None = 'NONE',
  Opened = 'OPENED',
}

export enum ReminderType {
  Episode = 'EPISODE',
  Patient = 'PATIENT',
}

export type RepeaterOrderableOptions = {
  __typename?: 'RepeaterOrderableOptions';
  buttonLabel?: Maybe<Scalars['String']>;
  canRepeat?: Maybe<Scalars['Boolean']>;
  readOnlyOnFinish?: Maybe<Scalars['Boolean']>;
  showDescription?: Maybe<Scalars['Boolean']>;
};

export type Role = IAuditable &
  ILookupValue & {
    __typename?: 'Role';
    createdBy: Scalars['Int'];
    createdByUser?: Maybe<User>;
    createdOn?: Maybe<Scalars['DateTime']>;
    displayName?: Maybe<Scalars['String']>;
    id: Scalars['Int'];
    name?: Maybe<Scalars['String']>;
    type: EnumRoleType;
    updatedBy?: Maybe<Scalars['Int']>;
    updatedByUser?: Maybe<User>;
    updatedOn?: Maybe<Scalars['DateTime']>;
  };

export type RowViewType =
  | PatientTimelineAttachmentRowViewModel
  | PatientTimelineChecklistRowViewModel
  | PatientTimelineReminderRowViewModel;

export type SaveQuickNoteDialogDtoInput = {
  quickNote: AddQuickNoteDtoInput;
  sessionId: Scalars['String'];
};

export type Scenario = IAuditable &
  ILookupValue & {
    __typename?: 'Scenario';
    createdBy: Scalars['Int'];
    createdByUser?: Maybe<User>;
    createdOn?: Maybe<Scalars['DateTime']>;
    displayName?: Maybe<Scalars['String']>;
    favorite?: Maybe<Scalars['Boolean']>;
    id: Scalars['Int'];
    index?: Maybe<Scalars['Int']>;
    isActive?: Maybe<Scalars['Boolean']>;
    name?: Maybe<Scalars['String']>;
    updatedBy?: Maybe<Scalars['Int']>;
    updatedByUser?: Maybe<User>;
    updatedOn?: Maybe<Scalars['DateTime']>;
    workflowDefinitionId?: Maybe<Scalars['UUID']>;
  };

export type SearchClaimRequestInput = {
  ascDesc?: InputMaybe<Scalars['String']>;
  claimDateEnd?: InputMaybe<Scalars['DateTime']>;
  claimDateStart?: InputMaybe<Scalars['DateTime']>;
  diagnosisCode?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patientId: Scalars['Int'];
  procedureCode?: InputMaybe<Scalars['String']>;
  searchTerm?: InputMaybe<Scalars['String']>;
};

export type SearchEpisodeRequestInput = {
  ascDesc?: InputMaybe<Scalars['String']>;
  cPTCode?: InputMaybe<Scalars['String']>;
  dXCode?: InputMaybe<Scalars['String']>;
  episodeEndDate?: InputMaybe<Scalars['DateTime']>;
  episodeStartDate?: InputMaybe<Scalars['DateTime']>;
  orderBy?: InputMaybe<Scalars['String']>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patientId: Scalars['Int'];
  searchTerm?: InputMaybe<Scalars['String']>;
};

export type SearchMedicationCodeActionContextInput = {
  term: Scalars['String'];
  type: MedicationCodeType;
};

export type SearchPatientDialogPatientsViewModel = {
  __typename?: 'SearchPatientDialogPatientsViewModel';
  dOB: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: Maybe<Scalars['String']>;
  mRN: Scalars['String'];
  mRN2?: Maybe<Scalars['String']>;
};

export type SearchResult = {
  __typename?: 'SearchResult';
  byCodeFound: Scalars['Boolean'];
  code: Scalars['String'];
  codeCategory?: Maybe<MedicationCodeCategoryType>;
  description?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  notificationMessage?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
};

export type SelectActionModelInput = {
  checklistId: Scalars['Int'];
  id: Scalars['UUID'];
  isSelected: Scalars['Boolean'];
};

export type SimpleLookup = ILookupValue & {
  __typename?: 'SimpleLookup';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

export type SimpleLookupInput = {
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
};

export type StartMozartWorkflowRequestInput = {
  episodeId: Scalars['Int'];
  id: Scalars['UUID'];
  patientId: Scalars['Int'];
  scenarioId: Scalars['Int'];
};

export type SuggestPatient = {
  __typename?: 'SuggestPatient';
  dOB: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: Maybe<Scalars['String']>;
  mRN: Scalars['String'];
  mRN2?: Maybe<Scalars['String']>;
};

export type SuggestProvider = {
  __typename?: 'SuggestProvider';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  specialityName?: Maybe<Scalars['String']>;
};

export type SystemValue = IAuditable & {
  __typename?: 'SystemValue';
  createdBy: Scalars['Int'];
  createdByUser?: Maybe<User>;
  createdOn?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  type?: Maybe<SystemValueType>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedByUser?: Maybe<User>;
  updatedOn?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
};

export enum SystemValueType {
  Glossary = 'GLOSSARY',
  System = 'SYSTEM',
}

export type Tag = IAuditable &
  ILookupValue & {
    __typename?: 'Tag';
    createdBy: Scalars['Int'];
    createdByUser?: Maybe<User>;
    createdOn?: Maybe<Scalars['DateTime']>;
    description?: Maybe<Scalars['String']>;
    id: Scalars['Int'];
    imagePath?: Maybe<Scalars['String']>;
    isDeleted?: Maybe<Scalars['Boolean']>;
    name?: Maybe<Scalars['String']>;
    sortOrder?: Maybe<Scalars['Int']>;
    type?: Maybe<TagType>;
    updatedBy?: Maybe<Scalars['Int']>;
    updatedByUser?: Maybe<User>;
    updatedOn?: Maybe<Scalars['DateTime']>;
  };

export type TagDtoInput = {
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
};

export enum TagType {
  ExternalData = 'EXTERNAL_DATA',
  Symphony = 'SYMPHONY',
  User = 'USER',
}

export type TatClockColumnViewModel = {
  __typename?: 'TatClockColumnViewModel';
  dueDateTime?: Maybe<Scalars['DateTime']>;
  state?: Maybe<ClockState>;
  timeRemaining?: Maybe<Scalars['String']>;
};

export type TatViewModel = {
  __typename?: 'TatViewModel';
  additionalTime?: Maybe<Scalars['String']>;
  calculatedTat?: Maybe<Scalars['String']>;
  clockType: Scalars['String'];
  drivers: Array<Scalars['String']>;
  dueDateTime?: Maybe<Scalars['DateTime']>;
  history: Array<ClockHistoryViewModel>;
  startDateTime: Scalars['DateTime'];
  state: ClockState;
  timeRemaining?: Maybe<Scalars['String']>;
};

export type TextAttributeType = {
  __typename?: 'TextAttributeType';
  dataType?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  entityName: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['ID'];
  maxLength?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  regexErrorText?: Maybe<Scalars['String']>;
  regexMask?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updateMask?: Maybe<Scalars['String']>;
};

export type TextExtractionModel = {
  __typename?: 'TextExtractionModel';
  boundingBox: BoundingBox;
  text: Scalars['String'];
};

export type TimelineRequestInput = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patientId: Scalars['Int'];
  searchTerm?: InputMaybe<Scalars['String']>;
  states?: InputMaybe<Array<InputMaybe<TimelineState>>>;
};

export type TimelineResponse = {
  __typename?: 'TimelineResponse';
  count?: Maybe<Scalars['Int']>;
  localDateTime?: Maybe<Scalars['String']>;
  rows?: Maybe<Array<Maybe<RowViewType>>>;
};

export enum TimelineState {
  Cm = 'CM',
  DocumentCompleted = 'DOCUMENT_COMPLETED',
  DocumentInProgress = 'DOCUMENT_IN_PROGRESS',
  HasAttachment = 'HAS_ATTACHMENT',
  HasEducation = 'HAS_EDUCATION',
  HasFax = 'HAS_FAX',
  HasLetter = 'HAS_LETTER',
  ReminderClosed = 'REMINDER_CLOSED',
  ReminderOpen = 'REMINDER_OPEN',
  Um = 'UM',
}

export type TimelineTabModel = {
  __typename?: 'TimelineTabModel';
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  orderDirection?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
  states?: Maybe<Array<Maybe<TimelineState>>>;
};

export enum TimerDaysType {
  All = 'ALL',
  Business = 'BUSINESS',
}

export enum TimerScaleType {
  Days = 'DAYS',
  Hours = 'HOURS',
}

export enum TimerValueType {
  Countdown = 'COUNTDOWN',
  Elapsed = 'ELAPSED',
}

export type UmRnDashboardCount = {
  __typename?: 'UmRnDashboardCount';
  completedToday: Scalars['Int'];
  csr: Scalars['Int'];
  csrClosedToday: Scalars['Int'];
  ipOpen: Scalars['Int'];
  nurseOwner: Scalars['String'];
  nurseOwnerId: Scalars['Int'];
  opOpen: Scalars['Int'];
  totalOpen: Scalars['Int'];
  totalOpenWork: Scalars['Int'];
  verifyAdmit: Scalars['Int'];
};

export type UniversalResultModel = {
  __typename?: 'UniversalResultModel';
  comment?: Maybe<Scalars['String']>;
  isSuccess?: Maybe<Scalars['Boolean']>;
};

export type UpdateActionValueModelInput = {
  checklistId: Scalars['Int'];
  componentId: Scalars['String'];
  groupId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  type: Scalars['String'];
  value?: InputMaybe<Scalars['String']>;
};

export type UpdateAttachmentDtoInput = {
  attachments: Array<AttachmentDtoInput>;
  episodeId: Scalars['Int'];
};

export type UpdateAttachmentsModelInput = {
  attachments: Array<AttachmentDtoInput>;
  episodeAttachments: Array<UpdateAttachmentDtoInput>;
  patientId: Scalars['Int'];
};

export type UpdateChecklistModelInput = {
  checklistId: Scalars['Int'];
  patientId: Scalars['Int'];
  problemIds?: InputMaybe<Array<InputMaybe<KeyValuePairOfInt32AndInt32Input>>>;
  scenarioId: Scalars['Int'];
  sessionId: Scalars['String'];
};

export type UpdateChecklistProblemModelInput = {
  carePlanStartDate?: InputMaybe<Scalars['DateTime']>;
  carePlanState?: InputMaybe<CarePlanState>;
  checklistId: Scalars['Int'];
  isCarePlanTab: Scalars['Boolean'];
  note?: InputMaybe<Scalars['String']>;
  outcomeId?: InputMaybe<Scalars['Int']>;
  priorityId?: InputMaybe<Scalars['Int']>;
  problemId: Scalars['Int'];
  progressId?: InputMaybe<Scalars['Int']>;
};

export type UpdateChecklistResult = {
  __typename?: 'UpdateChecklistResult';
  checklistId?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type UpdatePatientCareGapsModelInput = {
  careGaps?: InputMaybe<Array<InputMaybe<PatientCareGapInput>>>;
  patientId: Scalars['Int'];
};

export type UpdateReminderDtoInput = {
  episodeId?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
  reminders: Array<InputMaybe<ReminderDtoInput>>;
};

export type User = IAuditable &
  ILookupValue & {
    __typename?: 'User';
    canBeMemberOwner: Scalars['Boolean'];
    cmRnCaseLoadDashboardFilter?: Maybe<Scalars['String']>;
    createdBy: Scalars['Int'];
    createdByUser?: Maybe<User>;
    createdOn?: Maybe<Scalars['DateTime']>;
    credentials?: Maybe<Scalars['String']>;
    defaultDashboard?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    expeditedAuthorization?: Maybe<Scalars['String']>;
    firstName?: Maybe<Scalars['String']>;
    fullName?: Maybe<Scalars['String']>;
    id: Scalars['Int'];
    lastName?: Maybe<Scalars['String']>;
    login?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    prefix?: Maybe<Scalars['String']>;
    standardAuthorization?: Maybe<Scalars['String']>;
    suffix?: Maybe<Scalars['String']>;
    umRnDashboardFilter?: Maybe<Scalars['String']>;
    updatedBy?: Maybe<Scalars['Int']>;
    updatedByUser?: Maybe<User>;
    updatedOn?: Maybe<Scalars['DateTime']>;
  };

export type UserCaseLoadAndReminderCount = {
  __typename?: 'UserCaseLoadAndReminderCount';
  careTransitionCaseLoad: Scalars['Int'];
  caseManagementCaseLoad: Scalars['Int'];
  eRFollowUpCaseLoad: Scalars['Int'];
  reminderDueThisWeek: Scalars['Int'];
  reminderDueToday: Scalars['Int'];
  reminderOverDue: Scalars['Int'];
  totalCaseLoad: Scalars['Int'];
  userId: Scalars['Int'];
  userName: Scalars['String'];
};

export type UserFreeTextComponent = {
  __typename?: 'UserFreeTextComponent';
  color?: Maybe<Scalars['String']>;
  componentType: Scalars['String'];
  displayAsIcon: Scalars['Boolean'];
  displayOnlyWhenSelected: Scalars['Boolean'];
  id: Scalars['String'];
  linkMessage?: Maybe<Scalars['String']>;
  maxLen: Scalars['Int'];
  maxVal: Scalars['Int'];
  maxWidth: Scalars['Int'];
  minLen: Scalars['Int'];
  minVal: Scalars['Int'];
  mirrorGroup?: Maybe<Scalars['String']>;
  multiline: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  oneWaySrc?: Maybe<Scalars['String']>;
  readonly: Scalars['Boolean'];
  required: Scalars['Boolean'];
  richText: Scalars['Boolean'];
  style?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  uniqueID: Scalars['String'];
  useAutocomplete: Scalars['Boolean'];
  userDefinedId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  width: Scalars['Int'];
};

export type UserPreferencesViewModelInput = {
  answer1?: InputMaybe<Scalars['String']>;
  answer2?: InputMaybe<Scalars['String']>;
  answer3?: InputMaybe<Scalars['String']>;
  cmRnCaseLoadDashboardFilter?: InputMaybe<Scalars['String']>;
  createdOn?: InputMaybe<Scalars['DateTime']>;
  credentials?: InputMaybe<Scalars['String']>;
  currentPassword?: InputMaybe<Scalars['String']>;
  defaultDashboard?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  expeditedAuthorization?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  mFAConfigured?: InputMaybe<Scalars['Boolean']>;
  password?: InputMaybe<Scalars['String']>;
  passwordConfirm?: InputMaybe<Scalars['String']>;
  passwordReminder?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  prefix?: InputMaybe<Scalars['String']>;
  question1?: InputMaybe<Scalars['String']>;
  question2?: InputMaybe<Scalars['String']>;
  question3?: InputMaybe<Scalars['String']>;
  standardAuthorization?: InputMaybe<Scalars['String']>;
  suffix?: InputMaybe<Scalars['String']>;
  umRnDashboardFilter?: InputMaybe<Scalars['String']>;
  userName?: InputMaybe<Scalars['String']>;
};

export type UserRole = {
  __typename?: 'UserRole';
  careSite: CareSite;
  id: Scalars['Int'];
  isPrimary: Scalars['Boolean'];
  role: Role;
};

export type WorkFlowActionDataResult = {
  __typename?: 'WorkFlowActionDataResult';
  data?: Maybe<Array<Maybe<MozartWorkflowUiModel>>>;
  result: Scalars['Boolean'];
};

export type WorkflowInstanceAvailableOotSteps = {
  __typename?: 'WorkflowInstanceAvailableOOTSteps';
  createdBy: Scalars['Int'];
  createdOn: Scalars['DateTime'];
  id: Scalars['Int'];
  role?: Maybe<Scalars['String']>;
  roleIds?: Maybe<Array<Scalars['Int']>>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
  workflowInstance: Scalars['UUID'];
  workflowInstanceAvailableOOTStepsId: Scalars['UUID'];
  workflowStepDisplayName?: Maybe<Scalars['String']>;
  workflowStepId: Scalars['UUID'];
  workflowStepName?: Maybe<Scalars['String']>;
};
